const MAIN_FRAMES = {
  CONFIG_INFRASTRUCTURE_HOST: 'Config - Infrastructure > Host',
  CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW: 'Config - Host Group Setting Window',
  CONFIG_INFRASTRUCTURE_HOST_EDIT_WINDOW: 'Config - Host Edit Window',
  CONFIG_INFRASTRUCTURE_NETWORK_DEVICE: 'Config - Infrastructure > Network Device',
  CONFIG_INFRASTRUCTURE_DEVICE_WINDOW: 'Config - Network Device Setting Window',
  CONFIG_INFRASTRUCTURE_NETWORK_OBJECT: 'Config - Network Object',
  CONFIG_INFRASTRUCTURE_OID: 'Config - Infrastructure > OID',
  CONFIG_INFRASTRUCTURE_OID_WINDOW: 'Config - OID Setting Window',
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE: 'Config - Infrastructure > Message Queue',
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW:
    'Config - Infrastructure > Message Queue Group Setting Window',
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW:
    'Config - Infrastructure > Message Queue List Setting Window',

  CONFIG_CLOUD_AWS: 'Cloud - AWS',
  CONFIG_CLOUD_GROUP_WINDOW: 'Cloud - Group Window',
  CONFIG_CLOUD_RESOURCE_WINDOW: 'Cloud - Resource Window',
  CONFIG_CLOUD_ACCOUNT: 'Cloud - Account',
  CONFIG_CLOUD_ACCOUNT_WINDOW: 'Cloud - Account Window',
  CONFIG_CLOUD_COLLECTOR: 'Cloud - Collector',

  CONFIG_KUBERNETES: 'Config - Kubernetes',
  CONFIG_KUBERNETES_AGENT_INSTALL_WINDOW: 'Config - Kubernetes Agent Install Window',

  CONFIG_APPLICATION: 'Config - Application',
  CONFIG_APPLICATION_WAS_GROUP_WINDOW: 'Config - Application WAS Group Setting Window',
  CONFIG_APPLICATION_WAS_WINDOW: 'Config - Application WAS Setting Window',
  CONFIG_APPLICATION_RUM_GROUP_WINDOW: 'Config - Application RUM Group Setting Window',
  CONFIG_APPLICATION_RUM_WINDOW: 'Config - Application RUM Setting Window',
  CONFIG_APPLICATION_URL_MONITORING_WINDOW: 'Config - Application URL Monitoring Setting Window',

  CONFIG_DATABASE_INSTANCE: 'Config - Database > Instance',
  CONFIG_DATABASE_INSTANCE_GROUP_WINDOW: 'Config - Database Instance Group Setting Window',
  CONFIG_DATABASE_INSTANCE_WINDOW: 'Config - Database Instance Setting Window',
  //   CONFIG_DATABASE_AGENT: 'Config - Database > Agent',
  //   CONFIG_DATABASE_AGENT_WINDOW: 'Config - Agent Setting Window',

  CONFIG_EXTERNAL_RESOURCE: 'External Resource',
} as const;

export const CONFIG_PLATFORM_FRAME_NAMES = {
  // 인프라스트럭쳐
  CONFIG_INFRASTRUCTURE_HOST: {
    HOST_GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/Host Group List`,
    HOST_GROUP_INFORMATION: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/Host Group Information`,
    HOST_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/[Delete] Host Group`,
    HOST_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/Host List`,
    HOST_LIST_ALL: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/Host List - All`,
    HOST_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/[Delete] Host`,
    GROUP_MOVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST}/[Move] Group`,
  },
  CONFIG_INFRASTRUCTURE_HOST_EDIT_WINDOW: {
    HOST_CUSTOM_TAG: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_EDIT_WINDOW}/Instance Custom Tags`,
    HOST_UPDATE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_EDIT_WINDOW}/[Update] Host Information`,
  },
  CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW: {
    CONFIG_DETAIL: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/Config Detail`,
    CONFIG_ADD: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Add] Config`,
    CONFIG_EDIT: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Edit] Config`,
    CONFIG_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/Config List`,
    CONFIG_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Delete] CONFIG`,
    HOST_GROUP_UPDATE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Update] Host Group`,
    HOST_GROUP_ADD: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Add] Host Group`,
    HOST_LIST_UPDATE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_HOST_GROUP_WINDOW}/[Update] Host List`,
  },
  CONFIG_INFRASTRUCTURE_NETWORK_OBJECT: {
    NETWORK_OBJECT_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_OBJECT}/Network Object List`,
    NETWORK_OBJECT_CREATE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_OBJECT}/[Create] Network Object`,
    NETWORK_OBJECT_EDIT: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_OBJECT}/[Edit] Network Object`,
    NETWORK_OBJECT_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_OBJECT}/[Delete] Network Object`,
    NETWORK_OBJECT_GET: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_OBJECT}/[Get] Network Object`,
  },
  CONFIG_INFRASTRUCTURE_NETWORK_DEVICE: {
    NETWORK_GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_DEVICE}/Network Group List`,
    DEVICE_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_DEVICE}/Device List`,
    DEVICE_MOVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_DEVICE}/[Move] Device`,
    DEVICE_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_NETWORK_DEVICE}/[Delete] Device`,
  },
  CONFIG_INFRASTRUCTURE_NETWORK_WINDOW: {
    NETWORK_GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/Network Group List`,
    NETWORK_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/[Save] Network Group`,
  },
  CONFIG_INFRASTRUCTURE_DEVICE_WINDOW: {
    AGENT_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/Agent List`,
    DEVICE_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/Device List`,
    DEVICE_SAVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/[Save] Device`,
    CONNECTION_TEST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/[Test] Connection`,
    SNMP_SECURITY_INFO: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/SNMP Security Info`,
    EVENT_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_DEVICE_WINDOW}/[Delete] Device Event`,
  },
  CONFIG_INFRASTRUCTURE_OID: {
    OID_GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/Oid Group List`,
    OID_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/OID List`,
    OID_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/[Delete] OID`,
    OID_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/[Delete] OID Group`,
    OID_APPLY_AGENT: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID}/OID Apply Agent`,
  },
  CONFIG_INFRASTRUCTURE_OID_WINDOW: {
    OID_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/[Save] OID Group`,
    OID_SAVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/[Save] OID`,
    OID_EVENT_TYPE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/Event Type List`,
    OID_PACK_DETAIL: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/Oid Pack Detail`,
    OID_RESOURCE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_OID_WINDOW}/Oid Resource List`,
  },
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE: {
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/Group List`,
    GROUP_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/[Delete] Group`,
    MESSAGE_QUEUE_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/Message Queue List`,
    MESSAGE_QUEUE_LIST_DELETE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/[Delete] Message Queue List`,
    MESSAGE_QUEUE_LIST_MOVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE}/[Move] Message Queue List`,
  },
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW: {
    GROUP: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW}/Group`,
    GROUP_ADD: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW}/[Add] Group`,
    GROUP_EDIT: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_GROUP_WINDOW}/[Edit] Group`,
  },
  CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW: {
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/Group List`,
    MESSAGE_QUEUE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/Message Queue`,
    MESSAGE_QUEUE_ADD: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/[Add] Message Queue`,
    MESSAGE_QUEUE_EDIT: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/[Edit] Message Queue`,
    MESSAGE_QUEUE_MOVE: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/[Move] Message Queue`,
    AGENT_LIST: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/Agent List`,
    TEST_CONNECTION: `${MAIN_FRAMES.CONFIG_INFRASTRUCTURE_MESSAGE_QUEUE_LIST_WINDOW}/[Test] Connection`,
  },

  // 클라우드
  CLOUD_GROUP: {
    GET_GROUP_LIST: `${MAIN_FRAMES.CONFIG_CLOUD_AWS}/[GET] Group List`,
    MOVE_GROUP: `${MAIN_FRAMES.CONFIG_CLOUD_AWS}/[Move] Group`,
    GET_RESOURCE_LIST: `${MAIN_FRAMES.CONFIG_CLOUD_AWS}/[GET] Resource List`,
    DELETE_GROUP: `${MAIN_FRAMES.CONFIG_CLOUD_AWS}/[DELETE] Group`,
    POST_RESOURCE_ACTIVATE: `${MAIN_FRAMES.CONFIG_CLOUD_AWS}/[PUT] Resource Activate`,
  },
  CLOUD_GROUP_WINDOW: {
    GET_CLOUD_GROUP: `${MAIN_FRAMES.CONFIG_CLOUD_GROUP_WINDOW}/[GET] Cloud Group`,
    SAVE_CLOUD_GROUP: `${MAIN_FRAMES.CONFIG_CLOUD_GROUP_WINDOW}/[SAVE] Cloud Group`,
    EDIT_CLOUD_GROUP: `${MAIN_FRAMES.CONFIG_CLOUD_GROUP_WINDOW}/[EDIT] Cloud Group`,
  },
  CLOUD_RESOURCE_WINDOW: {
    GET_RESOURCE: `${MAIN_FRAMES.CONFIG_CLOUD_RESOURCE_WINDOW}/[GET] Resource`,
    EDIT_RESOURCE: `${MAIN_FRAMES.CONFIG_CLOUD_RESOURCE_WINDOW}/[EDIT] Resource`,
  },
  CLOUD_ACCOUNT: {
    GET_CLOUD_SERVICE_LIST: `${MAIN_FRAMES.CONFIG_CLOUD_ACCOUNT}/[GET] Cloud Service List`,
    GET_ACCOUNT_LIST: `${MAIN_FRAMES.CONFIG_CLOUD_ACCOUNT}/[GET] Account List`,
    DELETE_ACCOUNT: `${MAIN_FRAMES.CONFIG_CLOUD_ACCOUNT}/[DELETE] Account`,
    REFRESH_ACCOUNT: `${MAIN_FRAMES.CONFIG_CLOUD_ACCOUNT}/Refresh`,
  },
  CLOUD_ACCOUNT_WINDOW: {
    SAVE_CLOUD_AWS_ACCOUNT: `${MAIN_FRAMES.CONFIG_CLOUD_ACCOUNT_WINDOW}/[SAVE] Cloud Account Save`,
    EDIT_CLOUD_AWS_ACCOUNT: `${MAIN_FRAMES.CONFIG_CLOUD_ACCOUNT_WINDOW}/[EDIT] Cloud Account Edit`,
    GET_AGENT_LIST: `${MAIN_FRAMES.CONFIG_CLOUD_ACCOUNT_WINDOW}/[GET] Cloud Agent List`,
    TEST_CONNECTION: `${MAIN_FRAMES.CONFIG_CLOUD_ACCOUNT_WINDOW}/[TEST] Connection `,
  },
  CLOUD_COLLECTOR: {
    GET_SERVICE_LIST: `${MAIN_FRAMES.CONFIG_CLOUD_COLLECTOR}/[GET] Service List`,
    GET_COLLECTOR_LIST: `${MAIN_FRAMES.CONFIG_CLOUD_COLLECTOR}/[GET] Collector List`,
    COLLECTOR_SAVE: `${MAIN_FRAMES.CONFIG_CLOUD_COLLECTOR}/[SAVE] Collector`,
    DELETE_COLLECTOR: `${MAIN_FRAMES.CONFIG_CLOUD_COLLECTOR}/[DELETE] Collector`,
    GET_COLLECTOR: `${MAIN_FRAMES.CONFIG_CLOUD_COLLECTOR}/[GET] Collector`,
    GET_REGION_AND_METRIC_LIST: `${MAIN_FRAMES.CONFIG_CLOUD_COLLECTOR}/[GET] Region & Metric List by Service`,
  },

  // 쿠버네티스
  CONFIG_KUBERNETES: {
    CLUSTER_LIST: `${MAIN_FRAMES.CONFIG_KUBERNETES}/Cluster List`,
    CLUSTER_SAVE: `${MAIN_FRAMES.CONFIG_KUBERNETES}/[Save] Cluster`,
    CLUSTER_DELETE: `${MAIN_FRAMES.CONFIG_KUBERNETES}/[Delete] Cluster`,
    NAMESPACE_LIST: `${MAIN_FRAMES.CONFIG_KUBERNETES}/Namespace List`,
  },
  CONFIG_KUBERNETES_AGENT_INSTALL_WINDOW: {
    CONFIG_LIST: `${MAIN_FRAMES.CONFIG_KUBERNETES_AGENT_INSTALL_WINDOW}/Config List`,
    YAML_DOWNLOAD: `${MAIN_FRAMES.CONFIG_KUBERNETES_AGENT_INSTALL_WINDOW}/[Download] YAML`,
  },

  // 애플리케이션
  CONFIG_APPLICATION: {
    WAS_GROUP_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION}/WAS Group List`,
    WAS_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_APPLICATION}/[Save] WAS Group`,
    WAS_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION}/WAS List`,
    WAS_DELETE: `${MAIN_FRAMES.CONFIG_APPLICATION}/[Delete] WAS List`,
    URL_MONITORING_GROUP_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION}/URL Monitoring Group List`,
    URL_MONITORING_GROUP_MOVE: `${MAIN_FRAMES.CONFIG_APPLICATION}/URL Monitoring Group Move`,
    URL_MONITORING_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_APPLICATION}/[Delete] URL Monitoring Group`,
    URL_MONITORING_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION}/URL Monitoring List`,
    URL_MONITORING_DELETE: `${MAIN_FRAMES.CONFIG_APPLICATION}/[Delete] URL Monitoring`,
    RUM_GROUP_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION}/RUM Group List`,
    RUM_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_APPLICATION}/[Delete] RUM Group`,
    RUM_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION}/RUM List`,
    RUM_DELETE: `${MAIN_FRAMES.CONFIG_APPLICATION}/[Delete] RUM`,
    RUM_MOVE: `${MAIN_FRAMES.CONFIG_APPLICATION}/[Move] RUM`,
    RUM_SCRIPT: `${MAIN_FRAMES.CONFIG_APPLICATION}/RUM Script`,
  },
  CONFIG_APPLICATION_WAS_GROUP_WINDOW: {
    CONFIG_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/Config List`,
    CONFIG_ADD: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Add] Config`,
    CONFIG_DELETE: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Delete] Config`,
    CONFIG_EDIT: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Edit] Config`,
    WAS_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Save] WAS Group`,
  },
  CONFIG_APPLICATION_WAS_WINDOW: {
    WAS_LIST: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_WINDOW}/WAS List`,
    WAS_SAVE: `${MAIN_FRAMES.CONFIG_APPLICATION_WAS_GROUP_WINDOW}/[Save] WAS`,
  },
  CONFIG_APPLICATION_RUM_GROUP_WINDOW: {
    RUM_GROUP_EDIT: `${MAIN_FRAMES.CONFIG_APPLICATION_RUM_GROUP_WINDOW}/[Edit] RUM Group`,
    RUM_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_APPLICATION_RUM_GROUP_WINDOW}/[Save] RUM Group`,
  },
  CONFIG_APPLICATION_RUM_WINDOW: {
    RUM: `${MAIN_FRAMES.CONFIG_APPLICATION_RUM_WINDOW}/RUM`,
    RUM_EDIT: `${MAIN_FRAMES.CONFIG_APPLICATION_RUM_WINDOW}/[Edit] RUM`,
    RUM_SAVE: `${MAIN_FRAMES.CONFIG_APPLICATION_RUM_WINDOW}/[Save] RUM`,
  },
  CONFIG_APPLICATION_URL_MONITORING_WINDOW: {
    URL_MONITORING_GROUP_EDIT: `${MAIN_FRAMES.CONFIG_APPLICATION_URL_MONITORING_WINDOW}/[Edit] URL Group`,
    URL_MONITORING_GROUP_ADD: `${MAIN_FRAMES.CONFIG_APPLICATION_URL_MONITORING_WINDOW}/[Add] URL Group`,
  },

  // 데이터베이스
  CONFIG_DATABASE_INSTANCE: {
    INSTANCE_GROUP_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/Instance Group List`,
    INSTANCE_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/[Delete] Instance Group`,
    INSTANCE_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/Instance List`,
    INSTANCE_DELETE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/[Delete] Instance`,
    INSTANCE_MOVE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/[Move] Instance`,
    DATABASE_TYPE_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE}/Database Type List`,
  },
  CONFIG_DATABASE_INSTANCE_GROUP_WINDOW: {
    INSTANCE_GROUP_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_GROUP_WINDOW}/Instance Group List`,
    INSTANCE_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_GROUP_WINDOW}/[Save] Instance Group`,
  },
  CONFIG_DATABASE_INSTANCE_WINDOW: {
    AGENT_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Agent List`,
    INSTANCE_INFORMATION: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Instance Information`,
    INSTANCE_SAVE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/[Save] Instance`,
    INSTANCE_EDIT: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/[Edit] Instance`,
    INSTANCE_CUSTOM_TAG: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Instance Custom Tags`,
    INSTANCE_DEFAULT_CUSTOM_CONFIGURATION: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Default Custom Configuration`,
    INSTANCE_DB_METRIC_COLLECTION_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/DB Metric Collection List`,
    INSTANCE_DB_METRIC_COLLECTION_INFORMATION: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/DB Metric Collection Information`,
    INSTANCE_CLOUD_METRIC_COLLECTION_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Cloud Metric Collection List`,
    INSTANCE_CLOUD_METRIC_SERVICE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Cloud Metric Collection > Service`,
    INSTANCE_CLOUD_METRIC_COLLECTOR: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Cloud Metric Collection > Custom Collector Information`,
    INSTANCE_CLOUD_METRIC_COLLECTOR_INFORMATION: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/Cloud Metric Collection > Collector Information`,
    INSTANCE_CLOUD_RESOURCE: `${MAIN_FRAMES.CONFIG_DATABASE_INSTANCE_WINDOW}/OS Information > Cloud Resource`,
  },
  //   CONFIG_DATABASE_AGENT: {
  //     AGENT_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT}/Agent List`,
  //     AGENT_DELETE: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT}/[Delete] Agent List`,
  //     INSTANCE_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT}/Instance List`,
  //   },
  //   CONFIG_DATABASE_AGENT_WINDOW: {
  //     AGENT_LIST: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT_WINDOW}/Agent List`,
  //     ACCESS_TOKEN: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT_WINDOW}Access Token`,
  //     AGENT_SAVE: `${MAIN_FRAMES.CONFIG_DATABASE_AGENT_WINDOW}/[Save] Agent`,
  //   },

  CONFIG_EXTERNAL_RESOURCE: {
    EXTERNAL_RESOURCE_LIST: `${MAIN_FRAMES.CONFIG_EXTERNAL_RESOURCE}/External Resource List`,
    EXTERNAL_RESOURCE_DELETE: `${MAIN_FRAMES.CONFIG_EXTERNAL_RESOURCE}/[Delete] External Resource`,
    EXTERNAL_RESOURCE_ADD: `${MAIN_FRAMES.CONFIG_EXTERNAL_RESOURCE}/[ADD] External Resource`,
    EXTERNAL_RESOURCE_PUT: `${MAIN_FRAMES.CONFIG_EXTERNAL_RESOURCE}/[PUT] External Resource`,
    EXTERNAL_RESOURCE_TEST: `${MAIN_FRAMES.CONFIG_EXTERNAL_RESOURCE}/[TEST] External Resource`,
    EXTERNAL_RESOURCE_LIST_ROW: `${MAIN_FRAMES.CONFIG_EXTERNAL_RESOURCE}/External Resource List Row`,
  },
} as const;
