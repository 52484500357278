/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { ref, watch, computed } from 'vue';
import router from '@/common/router';
import { defineStore, storeToRefs } from 'pinia';
import { useDashboardViewStore } from '@/dashboard/stores/dashboard-view';
import { useGlobalFilterStore } from '@/dashboard/stores/global-filter';
import { CUSTOM_KEY } from '@/dashboard/utils/define';
import { useUserEnvStore } from '@/common/stores/user-env';
import { useDashboardUserEnvStore } from '@/dashboard/stores/dashboard-user-env';
import { USER_ENV_KEYS } from '@/common/define/userEnv.define';
import { useDashboardTargetsColorSyncStore } from '@/dashboard/stores/dashboard-targets-color-sync';
import { BY_TARGET_SERIES_TYPE } from '@/common/define/widget.define';
import {
  flattenWidgets,
  getAllRequestTagsRecursively,
  findKeyCustomStat,
} from '@/dashboard/utils/dashboardUtils';
import {
  useDashboardTargetsStore,
  type AllDashboardTargetsColumns,
} from '@/dashboard/stores/dashboard-targets';
import { isNil } from 'lodash-es';
import type { ArgParam } from '@/dashboard/components/widgets/useCustomStat';
import { convertToGridLayoutItemList } from '@/dashboard/adaptors';
import type { GridLayoutItem, WidgetModeType } from '@/dashboard/utils/types';
import type { WidgetUUID } from '@/common/utils';

type TargetId = string;
type WidgetId = string;
type GlobalFilterId = number;
export type MonitoringDashboardTarget = {
  targetName: string;
  category: string;
  children: [WidgetUUID, { widgetName: string; targetName: string }][];
  alias?: string;
  color?: string;
  originColor?: string;
  globalFilterId?: number;
};

export type MonitoringDashboardTargetList = Map<TargetId, MonitoringDashboardTarget>;
export type MonitoringDashboardTargetListArray = Array<
  [TargetId, Pick<MonitoringDashboardTarget, 'alias' | 'color'>]
>;

export interface TargetItem {
  widgetId: WidgetId;
  widgetName: string;
  category: string;
  targetId: TargetId;
  targetName: string;
  alias?: string;
  color?: string;
  originColor?: string;
  globalFilterId?: number;
}

export const LEGEND_DISPLAY_STYLE = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
} as const;

export type LegendDisplayStyle = (typeof LEGEND_DISPLAY_STYLE)[keyof typeof LEGEND_DISPLAY_STYLE];

export interface SelectedLegends {
  targetId?: TargetId;
  category?: string;
}

export interface LegendSaveInfo {
  isDashboardLegendOpen: boolean;
  legendDisplayStyle: LegendDisplayStyle;
  legendTheme: string;
  monitoringDashboardTargetList: MonitoringDashboardTargetListArray;
  selectedLegends: SelectedLegends[] | null;
}

const useLegendUserEnv = () => {
  const { getEnvValue, setEnvValue } = useUserEnvStore();
  const dashboardUserEnvStore = useDashboardUserEnvStore();
  const {
    getEditingUserEnvData,
    setEditingUserEnvData,
    getPreviewUserEnvData,
    setPreviewUserEnvData,
  } = dashboardUserEnvStore;

  const userEnvKeyGlobal = computed(
    () => `${USER_ENV_KEYS.LEGEND_dashboard_global}_${router.currentRoute.value.params.id}`,
  );

  const userEnvKeyCustom = computed(
    () => `${USER_ENV_KEYS.LEGEND_dashboard_user}_${router.currentRoute.value.params.id}`,
  );

  const getEnvData = (mode: WidgetModeType = 'edit') => {
    switch (mode) {
      case 'edit':
      case 'preview':
        return (
          getEditingUserEnvData(userEnvKeyGlobal.value, 'LEGEND') ??
          getEnvValue<LegendSaveInfo | null>(userEnvKeyCustom.value) ??
          getEnvValue<LegendSaveInfo | null>(userEnvKeyGlobal.value)
        );
      case 'view':
      default:
        return (
          getEnvValue<LegendSaveInfo | null>(userEnvKeyCustom.value) ??
          getEnvValue<LegendSaveInfo | null>(userEnvKeyGlobal.value)
        );
    }
  };

  return {
    userEnvKeyGlobal,
    userEnvKeyCustom,
    getEnvData,
    setEnvValue,
    setEditingUserEnvData,
    getPreviewUserEnvData,
    setPreviewUserEnvData,
  };
};

export const useDashboardLegendStore = defineStore('dashboardLegend', () => {
  const { userEnvKeyGlobal, userEnvKeyCustom, getEnvData, setEnvValue, setEditingUserEnvData } =
    useLegendUserEnv();

  const isDashboardLegendOpen = ref(false);
  const isLegendClicked = ref(false);
  const monitoringDashboardTargetList = ref<MonitoringDashboardTargetList>(new Map());
  const originMonitoringDashboardTargetList = ref<MonitoringDashboardTargetList>(new Map());
  const isLegendAllCheck = ref(true);
  const selectedLegends = ref<SelectedLegends[] | null>([]);
  const legendDisplayStyle = ref<LegendDisplayStyle>(LEGEND_DISPLAY_STYLE.HORIZONTAL);
  const legendTheme = ref(CUSTOM_KEY);
  const isChangedLegend = ref(false);

  const dashboardTargetsStore = useDashboardTargetsStore();
  const {
    setAllWidgetsByCurrentDashboard,
    setAllRequestTags,
    setAllDashboardTargetsColumns,
    fetchDashboardTargetsColumns,
  } = dashboardTargetsStore;
  const { allWidgetsByCurrentDashboard, allDashboardTargetsColumns, allDashboardCustomStats } =
    storeToRefs(dashboardTargetsStore);

  const dashboardViewStore = useDashboardViewStore();
  const { widgetsForMonitoringDashboard } = storeToRefs(dashboardViewStore);

  const { globalVariables } = storeToRefs(useGlobalFilterStore());

  const { setMatchingThemeSeriesColor, getMatchingThemeSeriesColor } =
    useDashboardTargetsColorSyncStore();

  const legendSaveInfo = computed<LegendSaveInfo>(() => ({
    isDashboardLegendOpen: isDashboardLegendOpen.value,
    legendDisplayStyle: legendDisplayStyle.value,
    legendTheme: legendTheme.value,
    monitoringDashboardTargetList: Array.from(monitoringDashboardTargetList.value.entries()).map(
      ([targetId, { alias, color, targetName }]) => [
        targetId,
        {
          alias,
          color,
          targetName,
        },
      ],
    ),
    selectedLegends: selectedLegends.value,
  }));

  const setDashboardLegendUserEnv = async (mode: WidgetModeType = 'edit') => {
    if (mode === 'edit') {
      setEditingUserEnvData(userEnvKeyGlobal.value, {
        chartType: 'LEGEND',
        global: true,
        value: legendSaveInfo.value,
      });
      setEditingUserEnvData(userEnvKeyCustom.value, {
        chartType: 'LEGEND',
        global: false,
        value: legendSaveInfo.value,
      });
    } else if (mode === 'view') {
      await setEnvValue([{ key: userEnvKeyCustom.value, value: legendSaveInfo.value }], false);
    }
  };

  const setLegendClick = (value: boolean) => {
    isLegendClicked.value = value;
  };

  const removeTargetListFromWidgetId = (
    targetList: MonitoringDashboardTargetList,
    widgetIdToRemove: WidgetId,
  ) => {
    targetList.forEach((target, targetId) => {
      target.children = target.children.filter(([widgetId]) => widgetId !== widgetIdToRemove);
      if (target.children.length === 0) {
        targetList.delete(targetId);
      }
    });
  };

  const removeObsoleteWidgetsByTargetItems = (targetItems: TargetItem[] | WidgetId) => {
    if (Array.isArray(targetItems)) {
      const targetItemsByTargetId = new Map<TargetId, TargetItem>();
      const targetItemsByWidgetId = new Map<WidgetId, TargetItem>();
      const targetItemsByGlobalFilterId = new Map<GlobalFilterId, TargetItem[]>();

      targetItems.forEach((item) => {
        targetItemsByTargetId.set(item.targetId, item);
        targetItemsByWidgetId.set(item.widgetId, item);
        if (item.globalFilterId) {
          const items = targetItemsByGlobalFilterId.get(item.globalFilterId) || [];
          items.push(item);
          targetItemsByGlobalFilterId.set(item.globalFilterId, items);
        }
      });

      monitoringDashboardTargetList.value.forEach((target, targetId) => {
        if (targetItemsByTargetId.has(targetId)) return;

        target.children = target.children.filter(
          ([widgetId]) => !targetItemsByWidgetId.has(widgetId),
        );

        if (target.children.length === 0) {
          monitoringDashboardTargetList.value.delete(targetId);
        }
      });

      originMonitoringDashboardTargetList.value.forEach((target, targetId) => {
        if (targetItemsByTargetId.has(targetId)) return;

        let shouldSkip = false;
        const targetGlobalFilterItems =
          target.globalFilterId !== undefined
            ? targetItemsByGlobalFilterId.get(target.globalFilterId) || []
            : [];

        if (targetGlobalFilterItems.length > 0) {
          for (const globalVariable of globalVariables.value) {
            if (globalVariable.targets.some(({ tagId }) => tagId === targetId)) {
              shouldSkip = true;
              break;
            }
          }
        }

        if (shouldSkip) return;

        target.children = target.children.filter(
          ([widgetId]) => !targetItemsByWidgetId.has(widgetId),
        );

        if (target.children.length === 0) {
          originMonitoringDashboardTargetList.value.delete(targetId);
        }
      });
    } else {
      const widgetIdToRemove = targetItems;
      removeTargetListFromWidgetId(monitoringDashboardTargetList.value, widgetIdToRemove);
      removeTargetListFromWidgetId(originMonitoringDashboardTargetList.value, widgetIdToRemove);
    }
  };

  const addOrUpdateTarget = (
    targetList: MonitoringDashboardTargetList,
    {
      targetId,
      widgetId,
      category,
      widgetName,
      targetName,
      alias,
      color,
      originColor,
      globalFilterId,
    }: TargetItem,
  ) => {
    if (category === 'business_service' || category === 'business_service_group') return;

    const existingTarget = targetList.get(targetId);

    if (existingTarget) {
      if (!existingTarget.children?.find((child) => child[0] === widgetId)) {
        existingTarget.children.push([widgetId, { widgetName, targetName }]);
      }
    } else {
      targetList.set(targetId, {
        targetName,
        color,
        alias,
        originColor,
        category,
        globalFilterId,
        children: [[widgetId, { widgetName, targetName }]],
      });
    }
  };

  const addOrUpdateTargets = (targetItems: TargetItem[]) => {
    targetItems.forEach((item) => {
      addOrUpdateTarget(monitoringDashboardTargetList.value, item);
      addOrUpdateTarget(originMonitoringDashboardTargetList.value, item);
    });
  };

  const sortTargetList = (
    targetList: MonitoringDashboardTargetList,
  ): MonitoringDashboardTargetList => {
    return new Map(
      [...targetList.entries()].sort((a, b) => a[1].targetName.localeCompare(b[1].targetName)),
    );
  };

  const sortMonitoringDashboardTargetList = () => {
    monitoringDashboardTargetList.value = sortTargetList(monitoringDashboardTargetList.value);
    originMonitoringDashboardTargetList.value = sortTargetList(
      originMonitoringDashboardTargetList.value,
    );
  };

  const mergedLegendByEnvValue = (legendList: MonitoringDashboardTargetList) => {
    const envData: LegendSaveInfo | null = getEnvData();
    const envDataMap = new Map(envData?.monitoringDashboardTargetList);

    const updatedMonitoringDashboardTargetList = new Map();
    legendList.forEach((originTarget, targetId) => {
      const currentTarget = envDataMap.get(targetId);
      if (currentTarget) {
        updatedMonitoringDashboardTargetList.set(targetId, {
          ...originTarget,
          color: currentTarget.color,
          alias: currentTarget.alias,
        });
      } else {
        updatedMonitoringDashboardTargetList.set(targetId, originTarget);
      }
    });

    return updatedMonitoringDashboardTargetList;
  };

  const setMonitoringDashboardTargetList = (targetItems: TargetItem[] | WidgetId) => {
    monitoringDashboardTargetList.value = mergedLegendByEnvValue(
      monitoringDashboardTargetList.value,
    );
    originMonitoringDashboardTargetList.value = mergedLegendByEnvValue(
      originMonitoringDashboardTargetList.value,
    );

    removeObsoleteWidgetsByTargetItems(targetItems);

    if (Array.isArray(targetItems)) {
      addOrUpdateTargets(targetItems);
    }

    sortMonitoringDashboardTargetList();
  };

  const getMonitoringDashboardTargetList = (targetId: string) => {
    return isDashboardLegendOpen.value && router.currentRoute.value.path.includes('dashboard')
      ? monitoringDashboardTargetList.value.get(targetId)
      : null;
  };

  const updateMonitoringDashboardTargetList = (
    newMonitoringdashboardTargetList: MonitoringDashboardTargetList,
  ) => {
    monitoringDashboardTargetList.value = newMonitoringdashboardTargetList;
  };

  const removeObsoleteWidgetsByWidgets = (widgets: GridLayoutItem[]) => {
    const removeObsoleteWidgets = (targetList: MonitoringDashboardTargetList) => {
      targetList.forEach((target, targetId) => {
        target.children = target.children.filter((child) => {
          return widgets.find((widget) => widget.i === child[0]);
        });

        if (target.children.length === 0) {
          targetList.delete(targetId);
        }
      });
    };

    removeObsoleteWidgets(monitoringDashboardTargetList.value);
    removeObsoleteWidgets(originMonitoringDashboardTargetList.value);
  };

  const setDefaultLegendValues = () => {
    isDashboardLegendOpen.value = false;
    legendDisplayStyle.value = LEGEND_DISPLAY_STYLE.HORIZONTAL;
    legendTheme.value = CUSTOM_KEY;
    selectedLegends.value = [];
  };

  const applyLegendConfig = (mode: WidgetModeType = 'edit') => {
    const envData: LegendSaveInfo | null = getEnvData(mode);

    if (envData) {
      const {
        isDashboardLegendOpen: isOpen,
        legendDisplayStyle: style,
        legendTheme: theme,
        selectedLegends: selected = [],
      } = envData;
      isDashboardLegendOpen.value = isOpen;
      legendDisplayStyle.value = style;
      legendTheme.value = theme;
      if (selected === null || selected.length) {
        setLegendClick(true);
      }
      selectedLegends.value = selected;
    } else {
      setDefaultLegendValues();
    }
  };

  const initializeDashboardTargetList = async (isInit = true) => {
    if (!isDashboardLegendOpen.value) {
      return;
    }

    let data: AllDashboardTargetsColumns[] = [];

    if (isInit) {
      let widgets: GridLayoutItem[] = [];
      if (allWidgetsByCurrentDashboard.value.length > 0) {
        widgets = allWidgetsByCurrentDashboard.value;
      } else if (
        !isNil(widgetsForMonitoringDashboard.value) &&
        !isNil(widgetsForMonitoringDashboard.value.widgets) &&
        widgetsForMonitoringDashboard.value.widgets.length > 0
      ) {
        widgets = convertToGridLayoutItemList(widgetsForMonitoringDashboard.value.widgets);
      }

      const filteredWidgets = widgets.filter(({ dataResource }) => dataResource === 'exemOne');
      if (filteredWidgets.length === 0) return;

      const allWidgets = flattenWidgets(filteredWidgets);
      const tempAllRequestTags = getAllRequestTagsRecursively(filteredWidgets);

      setAllWidgetsByCurrentDashboard(allWidgets);
      setAllRequestTags(tempAllRequestTags);
    }

    setAllDashboardTargetsColumns(await fetchDashboardTargetsColumns());

    data = allDashboardTargetsColumns.value ?? [];
    if (!data) return;

    const envData = getEnvData();

    const updatedMonitoringDashboardTargetList: MonitoringDashboardTargetList = new Map();

    let colorIndex = 0;
    data.forEach(({ widgetUUID, targets }) => {
      if (!widgetUUID) return;

      const matchedWidget = allWidgetsByCurrentDashboard.value?.find((widget) => {
        return widget.i === widgetUUID;
      });

      let widgetName = '';
      if (matchedWidget) {
        widgetName =
          typeof matchedWidget?.titleOption === 'string'
            ? JSON.parse(matchedWidget?.titleOption ?? '')?.titleText
            : matchedWidget?.titleOption?.titleText || matchedWidget?.chartType;
      }

      if (!targets || matchedWidget?.chartData?.[0].seriesType !== BY_TARGET_SERIES_TYPE) return;

      const targetsArr = targets.map(({ id, name }) => ({
        targetId: id || '',
        targetName: name || '',
      }));

      setMatchingThemeSeriesColor({
        curTheme: matchedWidget?.colorTheme || '',
        targets: targetsArr,
      });

      targets.forEach(({ id: targetId, name: targetName, ccategory, alias: aliasBySettings }) => {
        const existingEnvTarget = envData?.monitoringDashboardTargetList?.find(
          ([envTargetId]) => envTargetId === targetId,
        );

        const originColor = getMatchingThemeSeriesColor({
          colorTheme: matchedWidget?.colorTheme || CUSTOM_KEY,
          targetId: targetId || '',
          colorIndex,
        });
        const color = existingEnvTarget ? existingEnvTarget[1].color : originColor;
        const alias = existingEnvTarget
          ? existingEnvTarget[1].alias || aliasBySettings || ''
          : aliasBySettings || '';

        if (targetId && targetName) {
          if (updatedMonitoringDashboardTargetList.has(targetId)) {
            const existingTargetInMap = updatedMonitoringDashboardTargetList.get(targetId);
            if (
              !existingTargetInMap?.children.find(
                ([existingWidgetUUID]) => existingWidgetUUID === widgetUUID,
              )
            ) {
              existingTargetInMap?.children.push([widgetUUID, { widgetName, targetName }]);
            }
          } else {
            updatedMonitoringDashboardTargetList.set(targetId, {
              targetName,
              category: ccategory ?? '',
              children: [[widgetUUID, { widgetName, targetName }]],
              alias,
              color,
              originColor,
            });
          }
        }

        colorIndex++;
      });
    });

    if (isInit) {
      monitoringDashboardTargetList.value = sortTargetList(updatedMonitoringDashboardTargetList);
    }
    originMonitoringDashboardTargetList.value = sortTargetList(
      updatedMonitoringDashboardTargetList,
    );
  };

  const clearMonitoringDashboardTargetList = () => {
    setAllDashboardTargetsColumns([]);
    setAllWidgetsByCurrentDashboard([]);
    monitoringDashboardTargetList.value.clear();
    originMonitoringDashboardTargetList.value.clear();
  };

  const setAllDashboardTargetList = async (refreshMonitoring = false) => {
    await initializeDashboardTargetList(false);

    if (refreshMonitoring) {
      monitoringDashboardTargetList.value = sortTargetList(
        originMonitoringDashboardTargetList.value,
      );
    }
  };

  const initMonitoringDashboardTargetList = async () => {
    clearMonitoringDashboardTargetList();

    await initializeDashboardTargetList();

    applyLegendConfig();
  };

  const clearSelectedLegends = async ({ mode }: { mode: WidgetModeType }) => {
    selectedLegends.value = null;
    await setDashboardLegendUserEnv(mode);
  };

  const setSelectedLegends = async ({
    targetId,
    category,
    mode,
  }: {
    targetId?: TargetId;
    category?: string;
    mode?: WidgetModeType;
  }) => {
    if (targetId) {
      if (isNil(selectedLegends.value)) {
        selectedLegends.value = [];
      }
      isLegendAllCheck.value = false;
    }

    if (!targetId) {
      selectedLegends.value = [];

      if (mode) {
        await setDashboardLegendUserEnv(mode);
      }
      return;
    }

    if (selectedLegends.value?.some((legend) => legend.targetId === targetId)) {
      selectedLegends.value = selectedLegends.value.filter(
        (legend) => legend.targetId !== targetId,
      );

      if (selectedLegends.value?.length === 0) {
        selectedLegends.value = null;
      }

      if (mode) {
        await setDashboardLegendUserEnv(mode);
      }
      return;
    }

    selectedLegends.value?.push({
      targetId,
      category,
    });

    if (selectedLegends.value?.length === monitoringDashboardTargetList.value.size) {
      selectedLegends.value = [];
    }

    if (mode) {
      await setDashboardLegendUserEnv(mode);
    }
  };

  const updateLegendDisplayStyle = (style: LegendDisplayStyle) => {
    legendDisplayStyle.value = style;
  };

  const updateLegendTheme = (theme: string) => {
    legendTheme.value = theme;
  };

  const updateChangedLegend = (value: boolean) => {
    isChangedLegend.value = value;
  };

  const setAllDashboardCustomStats = ({
    widgetId,
    stats,
  }: {
    widgetId: WidgetId;
    stats: ArgParam[] | undefined;
  }) => {
    if (!stats || stats.length === 0) return;

    const existingStats = allDashboardCustomStats.value.get(widgetId) || [];
    const newStatsMap = new Map(
      existingStats.map((stat) => {
        const keyField = findKeyCustomStat(stat);
        return [keyField ? stat[keyField]?.value : null, stat];
      }),
    );

    stats.forEach((newStat) => {
      const keyField = findKeyCustomStat(newStat);
      if (!keyField) return;

      const keyValue = newStat[keyField]?.value;
      if (newStatsMap.has(keyValue)) {
        return;
      }
      newStatsMap.set(keyValue, newStat);
    });

    allDashboardCustomStats.value.set(widgetId, Array.from(newStatsMap.values()));
  };

  const clearWidgetCustomStats = (widgetId: WidgetId) => {
    allDashboardCustomStats.value.delete(widgetId);
  };

  const setDashboardLegendOpen = (value: boolean) => {
    const envData: LegendSaveInfo | null = getEnvData();

    const envValue: LegendSaveInfo = {
      isDashboardLegendOpen: value,
      legendDisplayStyle: envData?.legendDisplayStyle ?? LEGEND_DISPLAY_STYLE.HORIZONTAL,
      legendTheme: envData?.legendTheme ?? CUSTOM_KEY,
      monitoringDashboardTargetList: envData?.monitoringDashboardTargetList ?? [],
      selectedLegends: [],
    };

    isDashboardLegendOpen.value = value;
    setEditingUserEnvData(userEnvKeyGlobal.value, {
      chartType: 'LEGEND',
      global: true,
      value: envValue,
    });
    setEditingUserEnvData(userEnvKeyCustom.value, {
      chartType: 'LEGEND',
      global: false,
      value: envValue,
    });
  };

  watch(
    () => selectedLegends.value,
    (selects) => {
      if (selects?.length === 0) {
        isLegendAllCheck.value = true;
      } else {
        isLegendAllCheck.value = false;
      }
    },
    {
      flush: 'post',
    },
  );

  return {
    isDashboardLegendOpen,
    isLegendClicked,
    setDashboardLegendOpen,
    setLegendClick,
    monitoringDashboardTargetList,
    originMonitoringDashboardTargetList,
    mergedLegendByEnvValue,
    setMonitoringDashboardTargetList,
    getMonitoringDashboardTargetList,
    updateMonitoringDashboardTargetList,
    removeObsoleteWidgetsByWidgets,

    isLegendAllCheck,
    selectedLegends,
    clearSelectedLegends,
    setSelectedLegends,

    legendDisplayStyle,
    updateLegendDisplayStyle,

    legendTheme,
    updateLegendTheme,

    isChangedLegend,
    updateChangedLegend,

    setAllDashboardCustomStats,
    clearWidgetCustomStats,
    setAllDashboardTargetList,

    clearMonitoringDashboardTargetList,

    setDashboardLegendUserEnv,
    applyLegendConfig,

    initMonitoringDashboardTargetList,
  };
});
