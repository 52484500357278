import {
  GridCellInstance,
  GridDynamicStyleOption,
  GridHeaderInstance,
  GridRawRowDataType,
  GridRowDataType,
  GridRowInstance,
  GridSettingEnvValue,
} from '@/common/components/molecules/modernGrid/modernGrid.types';
import { isEqual, isNil, isArray } from 'lodash-es';

const getComparisonData = (data: any, excludeProp: string = 'children') => {
  const excludeProps = [excludeProp, 'rowId'];
  const excludeObject: Record<string, true> = {};
  excludeProps.forEach((prop) => {
    excludeObject[prop] = true;
  });

  return Object.entries(data)?.reduce(
    (acc, [key, value]) => {
      if (!excludeObject[key]) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>,
  );
};

export const getRowIdByData = <RowDataType extends GridRawRowDataType>(
  data: GridRawRowDataType,
  rowData: Map<string, GridRowDataType<RowDataType>> | undefined,
  excludeProp: string = 'children',
): string | undefined => {
  const rawData = getComparisonData(data, excludeProp);
  return Array.from(rowData?.entries() || []).find(([_, value]) =>
    isEqual(getComparisonData(value), rawData),
  )?.[0];
};

export const getRawRows = <RowDataType extends GridRawRowDataType>(
  state: true | Record<string, boolean>,
  rowsMap: Map<string, GridRowDataType<RowDataType>> | undefined,
): RowDataType[] => {
  const rawRows: RowDataType[] = [];
  Object.keys(state)?.forEach((rowId) => {
    const rowData = rowsMap?.get(rowId);
    if (state[rowId] && rowData && !rowData.isDetail) {
      const { rowId: id, ...rowDataWithoutId } = rowData;
      rawRows.push(rowDataWithoutId as unknown as RowDataType);
    }
  });
  return rawRows;
};

const findLastHeaderDepth = <RowDataType extends GridRawRowDataType>(
  header: GridHeaderInstance<RowDataType>,
  columnId: string,
): number => {
  if (!header.subHeaders || header.subHeaders.length === 0) {
    return header.depth;
  }
  const matchingSubHeader = header.subHeaders.find((subHeader) => subHeader.column.id === columnId);
  if (matchingSubHeader) {
    return findLastHeaderDepth(matchingSubHeader, columnId);
  }
  return header.depth;
};

export const getHeaderRowSpan = <RowDataType extends GridRawRowDataType>(
  header: GridHeaderInstance<RowDataType>,
): number => {
  const columnId = header.column.id;
  const currentDepth = header.headerGroup.depth;
  const lastDepth = findLastHeaderDepth(header, columnId);
  return header.column.depth === header.headerGroup.depth ? lastDepth - currentDepth : 0;
};

export const checkNumberStr = (str: string) => /^\d+(\.\d+)?$/.test(str);

export const matchArray = (cellValue: any, keyword: string) => {
  return (
    Array.isArray(cellValue) &&
    cellValue.some((item: any) => {
      const chipName = typeof item === 'object' && item?.name ? item.name : item;
      return chipName.toLowerCase().includes(keyword);
    })
  );
};

export const matchStringValue = (cellValue: any, keyword: string) => {
  return cellValue.toLowerCase().includes(keyword);
};

export const matchesCondition = <RowDataType extends GridRawRowDataType>(
  styleOption: GridDynamicStyleOption,
  instance: GridCellInstance<RowDataType> | GridRowInstance<RowDataType>,
): boolean => {
  const { columnId, type, condition } = styleOption;
  const isCellCondition = type === 'cell';
  const isCellData = 'column' in instance;
  const instanceColumnId = isCellData ? instance.column.id : '';
  const originalData = isCellData ? instance.row.original : instance.original;

  if (isCellCondition !== isCellData) {
    return false;
  }

  return isCellCondition
    ? columnId === instanceColumnId && condition(originalData[columnId])
    : condition(originalData[columnId]);
};

export const deepCompare = (
  data1: any,
  data2: any,
  options: { exceptCompareKeys?: string[] } = {},
): boolean => {
  const { exceptCompareKeys = [] } = options;

  if (data1 === null || data2 === null) {
    return data1 === data2;
  }

  if (typeof data1 !== typeof data2) return false;

  if (Array.isArray(data1) && Array.isArray(data2)) {
    if (data1.length !== data2.length) return false;
    return data1.every((item, index) => deepCompare(item, data2[index], { exceptCompareKeys }));
  }

  if (
    typeof data1 === 'object' &&
    typeof data2 === 'object' &&
    !(data1 === null || data2 === null)
  ) {
    const keys1 = Object.keys(data1);
    const keys2 = Object.keys(data2);

    if (keys1.length !== keys2.length) return false;
    return keys1.every((key) => {
      if (exceptCompareKeys?.includes(key)) return true;
      return deepCompare(data1[key], data2[key], { exceptCompareKeys });
    });
  }

  if (
    (typeof data1 === 'string' || typeof data1 === 'number' || typeof data1 === 'boolean') &&
    (typeof data2 === 'string' || typeof data2 === 'number' || typeof data2 === 'boolean')
  ) {
    return data1 === data2;
  }

  return true;
};

export const existObjectValue = (data: Record<string, any> | undefined): boolean =>
  !!data && typeof data === 'object' && !!Object.keys(data)?.length;

export const isEqualArrayValue = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

export const isChangedEnv = <K extends keyof GridSettingEnvValue>(
  envValue: GridSettingEnvValue[K],
  newValue: GridSettingEnvValue[K],
  isArrayType: boolean = false,
): boolean => {
  const hasChanged = envValue && !deepCompare(envValue, newValue);
  const isNilEnv = isNil(envValue);
  const isNonEmptyNewValue =
    newValue &&
    (isArrayType && isArray(newValue) ? newValue.length > 0 : existObjectValue(newValue));

  return hasChanged || (isNilEnv && isNonEmptyNewValue);
};
