import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { isEmpty } from 'lodash-es';
import { useViewModeStore } from '@/common/stores/view-mode';
import { VIEW_MODE } from '@/common/define/common.define';
import router from '@/common/router';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { useSimpleTextTooltip } from '@/common/components/molecules/simpleTextTooltip/simpleTextTooltip.uses';
import { useInternational } from '@/common/locale';
import { useInstanceStore } from '@/common/stores/instance';
import type { MenuInfo } from './types';
import { useDashboardMenu, useMenu } from './baseMenu.uses';
import { useShowMainPopup } from './popups/popups.uses';
import { BASE_MENU_VALUE } from './baseMenu.define';
import { DASHBOARD_MENU_TYPE } from '../../molecules/breadCrumbs/breadCrumbs.define';

export const setup = () => {
  const { t } = useInternational();
  const { findMenuItem, menuInfo, helpMenuInfo, myInfoMenuInfo } = useMenu();
  const { viewMode, productName } = storeToRefs(useViewModeStore());
  const { dashboardMenuInfo, analysisBoardMenuInfo } = useDashboardMenu(menuInfo.value);

  const baseMenuStore = useBaseMenuStore();
  const {
    menuVisitRoute: menuVisitRouteStore,
    mainPanelInfo,
    subPanelInfo,
    isBaseMenuHidden,
  } = storeToRefs(baseMenuStore);
  const {
    updateLastMenu,
    getLastMenu,
    openSubPanel,
    closeSubPanel,
    selectCategory,
    selectSubPanelMenu,
    toggleCategory,
    shrinkMainPanel,
  } = baseMenuStore;
  const { changeGNBInstance } = useInstanceStore();

  const { baseMenuRef, popupMenuRef, popupMenuStyle, setHoveredItemInfo } = useShowMainPopup();

  const isMyInfoMenuClicked = ref<boolean>(false);
  const hoveredMenuInfo = ref<MenuInfo | null>(null);
  const tooltipMouseEvent = ref<MouseEvent | null>(null);

  const { onMouseEventInTarget } = useSimpleTextTooltip(tooltipMouseEvent);

  const currentMenuVisitRoute = computed<MenuInfo[]>(() => {
    const visitRoute: MenuInfo[] = [];
    findMenuItem(
      menuInfo.value,
      (menu: MenuInfo) => menu.path === router.currentRoute.value.path,
      visitRoute,
    );
    return visitRoute;
  });

  const currentRootMenu = computed<string>(() => {
    const [, rootPath, ...subPaths] = router.currentRoute.value.path.split('/');
    if (!isEmpty(router.currentRoute.value.query)) {
      const { rootMenu } = router.currentRoute.value.query;
      return (rootMenu as string) ?? '';
    }

    if (rootPath === 'dashboard' || rootPath === 'myInfo') {
      return rootPath;
    }
    if (rootPath === BASE_MENU_VALUE.FAULT_ANALYSIS) {
      return BASE_MENU_VALUE.PA;
    }
    if (
      ['singleView', 'multiView'].includes(subPaths[0]) &&
      viewMode.value !== VIEW_MODE.MAXGAUGE
    ) {
      return 'database';
    }
    if (
      rootPath === BASE_MENU_VALUE.ORACLE ||
      rootPath === BASE_MENU_VALUE.POSTGRESQL ||
      rootPath === BASE_MENU_VALUE.MYSQL ||
      rootPath === BASE_MENU_VALUE.SQLSERVER
    ) {
      if (viewMode.value !== VIEW_MODE.MAXGAUGE) {
        return BASE_MENU_VALUE.PA;
      }
      return rootPath;
    }

    if (!currentMenuVisitRoute.value.length) {
      return '';
    }
    return currentMenuVisitRoute.value[0].value ?? '';
  });

  const onClickMainCategory = (menu: MenuInfo) => {
    // 이동할 경로 계산
    let targetPath: string | undefined;

    if (menu.value === mainPanelInfo.value.selectedCategory) {
      toggleCategory(menu.value); // 메뉴바 축소
      return;
    }

    if (
      menu.dashboardList &&
      dashboardMenuInfo.value[menu.value]?.length > 0 &&
      dashboardMenuInfo.value[menu.value][0].path
    ) {
      // 하위 메뉴에 대시보드가 존재할 경우
      targetPath = dashboardMenuInfo.value[menu.value][0].path!;
      toggleCategory(menu.value, BASE_MENU_VALUE.DASHBOARD);
      openSubPanel({ ...menu.dashboardList!, subMenuList: dashboardMenuInfo.value[menu.value] });
      selectSubPanelMenu(dashboardMenuInfo.value[menu.value][0].value);
    } else if (
      menu.analysisBoardList &&
      analysisBoardMenuInfo.value[menu.value]?.length > 0 &&
      analysisBoardMenuInfo.value[menu.value][0].path
    ) {
      // 하위 메뉴에 분석보드가 존재할 경우
      targetPath = analysisBoardMenuInfo.value[menu.value][0].path!;
      toggleCategory(menu.value, BASE_MENU_VALUE.ANALYSISBOARD);
      openSubPanel({
        ...menu.analysisBoardList!,
        subMenuList: analysisBoardMenuInfo.value[menu.value],
      });
      selectSubPanelMenu(analysisBoardMenuInfo.value[menu.value][0].value);
    } else {
      // 하위 메뉴에 대시보드 없음
      let menuNode: MenuInfo | null = null;
      targetPath = getLastMenu(menu.value);

      const predicateFn = (info: MenuInfo) =>
        !info.children?.length && !info.dashboardList && !info.subMenuList && !!info.path;

      if (!targetPath) {
        if (menu.value === BASE_MENU_VALUE.DASHBOARD) {
          targetPath = '/dashboard/list';
        } else if (menu.value === BASE_MENU_VALUE.SETTINGS) {
          targetPath = '/myInfo';
        } else {
          menuNode = findMenuItem([menu], predicateFn);
          targetPath = menuNode?.path ?? '';
        }
      }

      // 메뉴바 확장
      if (!menuNode) {
        if (
          targetPath.includes(`menuType=${DASHBOARD_MENU_TYPE.DASHBOARD}`) ||
          targetPath.includes(`menuType=${DASHBOARD_MENU_TYPE.ANALYSIS_DASHBOARD}`)
        ) {
          menuNode = findMenuItem([menu], predicateFn);
          targetPath = menuNode?.path ?? '';
        } else {
          menuNode = findMenuItem([menu], (info) => info.path === targetPath);
        }
      }
      toggleCategory(menu.value, menuNode?.value);

      if (menu.value === BASE_MENU_VALUE.CLOUD) {
        openSubPanel({ ...menu.children?.[0].children?.[0]! });
        selectSubPanelMenu(menuNode?.value!);
      }
    }

    if (hoveredMenuInfo.value) {
      hoveredMenuInfo.value = null;
    }
    if (targetPath) {
      const [, rootPath, secondPath] = targetPath.split('/');
      if (secondPath === 'multiView' || secondPath === 'singleView') {
        changeGNBInstance(rootPath, secondPath);
      }
      router.push(targetPath);
    }
  };

  const onClickMyInfoCategory = (evt: MouseEvent) => {
    setHoveredItemInfo(evt);
    toggleCategory(BASE_MENU_VALUE.SETTINGS);
    isMyInfoMenuClicked.value = true;
  };

  const onClickSupportCategory = () => {
    toggleCategory(BASE_MENU_VALUE.HELP);
    openSubPanel(helpMenuInfo);
    const selectedChildren = helpMenuInfo.subMenuList[0].children?.find(({ path }) =>
      currentRootMenu.value === BASE_MENU_VALUE.PA
        ? path?.endsWith(BASE_MENU_VALUE.ANALYSIS)
        : path?.endsWith(currentRootMenu.value),
    );
    if (selectedChildren && selectedChildren.path) {
      selectSubPanelMenu(selectedChildren.value);
      router.push(selectedChildren.path);
    }
  };

  const onShrinkMainPanel = () => {
    if (
      subPanelInfo.value.openedSubPanelMenuInfo &&
      subPanelInfo.value.openedSubPanelMenuInfo.value !== BASE_MENU_VALUE.SETTINGS
    ) {
      closeSubPanel();
    }
    if (mainPanelInfo.value.isMainPanelExpanded) {
      shrinkMainPanel();
    }
  };

  const onHoverMainCategory = (evt: MouseEvent) => {
    if (
      mainPanelInfo.value.isMainPanelExpanded ||
      subPanelInfo.value.openedSubPanelMenuInfo ||
      isMyInfoMenuClicked.value ||
      evt.button !== 0
    ) {
      return; // nav expanded 되었을 경우에는 hover 동작 X
    }
    const menuValue = setHoveredItemInfo(evt);
    hoveredMenuInfo.value = findMenuItem(menuInfo.value, (info) => info.value === menuValue);
  };

  const onHoverHelpCategory = (evt: MouseEvent) => {
    if (isMyInfoMenuClicked.value || evt.button !== 0) {
      return;
    }

    const menuValue = setHoveredItemInfo(evt);
    if (menuValue === BASE_MENU_VALUE.SETTINGS) {
      hoveredMenuInfo.value = myInfoMenuInfo;
    } else if (menuValue === BASE_MENU_VALUE.HELP) {
      hoveredMenuInfo.value = helpMenuInfo;
    }
  };

  const onHideFloatingMenu = () => {
    hoveredMenuInfo.value = null;
  };

  const onHideMyInfoPopup = () => {
    isMyInfoMenuClicked.value = false;
  };

  const setSettingPanel = () => {
    openSubPanel(myInfoMenuInfo);
    selectSubPanelMenu(currentMenuVisitRoute.value[2].value);
  };

  const setHelpPanel = () => {
    openSubPanel(helpMenuInfo);
    selectSubPanelMenu(currentMenuVisitRoute.value[2].value);
  };

  const onClickSetting = () => {
    let targetPath = getLastMenu('myInfo');

    if (!targetPath || !targetPath.length) {
      targetPath = '/myInfo';
    }

    onHideMyInfoPopup();
    if (targetPath === router.currentRoute.value.path) {
      setSettingPanel();
    }
    router.push(targetPath);
  };

  const onClickTitle = () => {
    router.push('/');
  };

  const onClickOutsideMainPanel = () => {
    // 설정 화면에서만 적용됨 DSP-15084
    if (currentRootMenu.value === BASE_MENU_VALUE.SETTINGS) {
      shrinkMainPanel();
    }
  };

  const onPathChange = () => {
    // 경로 변경될 때마다 우측 패널 내용물 검사
    // 경로변경 예시: 뒤로가기, 링크클릭 등
    const subMenuIdx = currentMenuVisitRoute.value.findIndex((menu) => menu.subMenuList?.length);
    const { rootMenu, menuType } = router.currentRoute.value.query; // for dashboard & analysisboard
    const { id } = router.currentRoute.value.params; // for dashboard & analysisboard

    if (currentRootMenu.value === BASE_MENU_VALUE.SETTINGS) {
      setSettingPanel();
      return;
    }
    if (currentRootMenu.value === BASE_MENU_VALUE.HELP) {
      setHelpPanel();
      return;
    }

    if (subPanelInfo.value.openedSubPanelMenuInfo) {
      const keepOpenValue: string[] = [BASE_MENU_VALUE.KUBERNETES, BASE_MENU_VALUE.CLOUD];
      if (currentRootMenu.value === BASE_MENU_VALUE.PA && subMenuIdx !== -1) {
        openSubPanel(currentMenuVisitRoute.value[subMenuIdx]);
        selectSubPanelMenu(currentMenuVisitRoute.value[4].value);
      } else if (keepOpenValue.includes(currentRootMenu.value) && subMenuIdx !== -1) {
        openSubPanel(currentMenuVisitRoute.value[subMenuIdx]);
        selectSubPanelMenu(currentMenuVisitRoute.value[4].value);
      } else if (menuType === DASHBOARD_MENU_TYPE.DASHBOARD) {
        openSubPanel({
          text: t('WORD.GNB.DASHBOARD'),
          value: 'dashboard',
          subMenuList: dashboardMenuInfo.value[rootMenu as string] ?? [],
        });
        selectSubPanelMenu(`dashboard_Dashboard View_${id}`);
      } else if (menuType === DASHBOARD_MENU_TYPE.ANALYSIS_DASHBOARD) {
        openSubPanel({
          text: t('WORD.GNB.ANALYSISBOARD'),
          value: 'analysisBoard',
          subMenuList: analysisBoardMenuInfo.value[rootMenu as string] ?? [],
        });
        selectSubPanelMenu(`dashboard_Dashboard View_${id}`);
      } else {
        closeSubPanel();
      }
    } else if (
      menuType === DASHBOARD_MENU_TYPE.DASHBOARD ||
      menuType === DASHBOARD_MENU_TYPE.ANALYSIS_DASHBOARD
    ) {
      selectCategory({ menuValue: currentRootMenu.value, subMenuValue: menuType });
    }
  };

  watch(
    router.currentRoute,
    (newRoute) => {
      updateLastMenu(currentRootMenu.value, newRoute.fullPath);
      menuVisitRouteStore.value = currentMenuVisitRoute.value;
      const SUB_MENU_IDX = currentMenuVisitRoute.value.length >= 3 ? 2 : 1;
      selectCategory({
        menuValue: currentRootMenu.value,
        subMenuValue: currentMenuVisitRoute.value?.[SUB_MENU_IDX]?.value,
      });

      // NOTE: 이 밑의 로직은 GNB 설정값 계정 저장시 전체적으로 바꿀 필요 있음
      // 기본적으로 settings, help 의 경우 관련 페이지 접근 시 우측 패널 열리게끔 짜여져 있으나
      // GNB 설정값 계정 저장 기능 구현할 경우 우측 패널 자동 Open 기능이 무의미하기 때문

      onPathChange();
    },
    { immediate: true },
  );

  watch(
    [dashboardMenuInfo, analysisBoardMenuInfo],
    ([dashboardMenu, analysisMenu]) => {
      // NOTE: 대시보드 메뉴랑 분석보드 메뉴는 서버에서 받아오기 때문에 업데이트가 느림
      // 경로 바꾸는 시점에서 해당 메뉴목록이 비어있을 수 있어서 watch 통해 refetch 필요
      if (subPanelInfo.value.openedSubPanelMenuInfo) {
        const { rootMenu, menuType } = router.currentRoute.value.query; // for dashboard & analysisboard
        if (menuType === DASHBOARD_MENU_TYPE.DASHBOARD) {
          subPanelInfo.value.openedSubPanelMenuInfo.subMenuList =
            dashboardMenu[rootMenu as string] ?? [];
        } else if (menuType === DASHBOARD_MENU_TYPE.ANALYSIS_DASHBOARD) {
          subPanelInfo.value.openedSubPanelMenuInfo.subMenuList =
            analysisMenu[rootMenu as string] ?? [];
        }
      }
    },
    { deep: true },
  );

  return {
    t,

    menuInfo,
    dashboardMenuInfo,
    analysisBoardMenuInfo,
    helpMenuInfo,
    myInfoMenuInfo,

    productName,

    mainPanelInfo,
    hoveredMenuInfo,
    isMyInfoMenuClicked,

    baseMenuRef,
    popupMenuRef,
    popupMenuStyle,
    tooltipMouseEvent,
    isBaseMenuHidden,

    onMouseEventInTarget,
    onHoverMainCategory,
    onHoverHelpCategory,
    onClickMainCategory,
    onClickMyInfoCategory,
    onClickSupportCategory,
    onShrinkMainPanel,
    onHideFloatingMenu,
    onHideMyInfoPopup,
    onClickTitle,
    onClickSetting,
    onClickOutsideMainPanel,
  };
};
