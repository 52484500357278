/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  ApiResponseFilterItem,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getFiltersCloudFilterV8ControllerAxios = ({
  cloud,
  cloudBasicViewService,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  cloud: 'aws';
  cloudBasicViewService:
    | 'cost-explorer'
    | 'dms'
    | 'documentdb'
    | 'dynamodb'
    | 'ec2'
    | 'elasticache'
    | 'lambda'
    | 'rds'
    | 'redshift'
    | 's3';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseFilterItem> => {
  const localVarPath = '/api/v8/cloud/{cloud}/{cloudBasicViewService}/filter'
    .replace(`{${'cloud'}}`, encodeURIComponent(String(cloud)))
    .replace(`{${'cloudBasicViewService'}}`, encodeURIComponent(String(cloudBasicViewService)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export { getFiltersCloudFilterV8ControllerAxios };
