import {
  ref,
  computed,
  watch,
  provide,
  onMounted,
  nextTick,
  type Ref,
  onUnmounted,
  getCurrentInstance,
} from 'vue';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { storeToRefs } from 'pinia';
import { useStore } from '@/common/store';
import { useInstanceStore, type InstanceType } from '@/common/stores/instance';
import { useViewModeStore } from '@/common/stores/view-mode';
import { useDashboardListViewStore } from '@/dashboard/stores/dashboard-list-view';
import { useDashboardViewStore } from '@/dashboard/stores/dashboard-view';
import { useInternational } from '@/common/locale';
import { DATABASE_MULTI_VIEW_TYPES, DATABASE_SINGLE_VIEW_TYPES } from '@/database/utils/define';
import type { FolderInstanceItem, FolderInstance } from '@/openapi/meta/model';
import { VIEW_MODE } from '@/common/define/common.define';
import { useRoute, useRouter } from 'vue-router';
import { BASE_MENU_VALUE } from '@/common/components/organisms/baseMenu/baseMenu.define';
import type { MenuInfo } from '@/common/components/organisms/baseMenu/types';
import { showErrorMsg } from '@/common/utils/commonUtils';
import {
  BREAD_CRUMBS_MODAL_CLASS,
  BREAD_CRUMBS_CHANGE_FAVORITE_KEY,
  DASHBOARD_MENU_TYPE,
} from './breadCrumbs.define';
import type {
  MenuMouseEvent,
  BreadCrumbMenuInfo,
  MenuPosition,
  BreadCrumbMode,
} from './breadCrumbs.types';

export interface Props {
  mode?: BreadCrumbMode;
  dbInstanceType?: InstanceType;
  dbType?: string;
}

interface InstanceButton {
  text: string;
  instanceType: InstanceType;
}

// Common
const useMenuRoutes = () => {
  const baseMenuStore = useBaseMenuStore();
  const { menuVisitRoute } = storeToRefs(baseMenuStore);

  // GNB 부터(LNB 그룹명 제외)
  const filteredPaths = computed<MenuInfo[]>(() => {
    return menuVisitRoute.value.filter(
      (menu, i) =>
        i === 0 || !menu.children || menu.subMenuList || i === menuVisitRoute.value.length - 1,
    );
  });

  const getSiblingMenuPath = (i?: number): BreadCrumbMenuInfo => {
    return filteredPaths.value[(i ?? 1) - 1];
  };

  return {
    filteredPaths,
    getSiblingMenuPath,
  };
};

// Instances
const useMenuInstances = (props: Props) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const instanceStore = useInstanceStore();
  const { getInstances, findMultiView, setCurrentInstance } = instanceStore;
  const { instances, currentInstance } = storeToRefs(instanceStore);

  const viewModeStore = useViewModeStore();
  const { viewMode } = storeToRefs(viewModeStore);

  const instanceTitleByViewMode = computed(() => {
    return viewMode.value === VIEW_MODE.MAXGAUGE ? t('WORD.OVERVIEW') : t('WORD.DATABASE');
  });
  const instanceTypeButtons = computed<InstanceButton[]>(() => {
    if (props.dbInstanceType === 'multiView') {
      return [{ text: currentInstance.value.multiView.name, instanceType: 'multiView' }];
    }
    return [
      { text: currentInstance.value.multiView.name, instanceType: 'multiView' },
      { text: currentInstance.value.singleView.name, instanceType: 'singleView' },
    ];
  });

  // 멀티뷰
  const getInstanceMultiMenuList = (): BreadCrumbMenuInfo => {
    return {
      children: instances.value
        ?.filter(
          (instance) =>
            DATABASE_MULTI_VIEW_TYPES[instance.type!] || // LINK - BreadCrumbs
            instance.children?.find((child) => DATABASE_SINGLE_VIEW_TYPES[child.type!]),
        )
        .map((instance) => ({
          text: instance.name ?? 'Not Found',
          value: instance.id ?? 'Not Found',
          dbType: instance.type,
          instanceType: 'multiView',
          children:
            instance.children
              ?.filter((child) => DATABASE_SINGLE_VIEW_TYPES[child.type!])
              .map((child) => ({
                text: child.name ?? 'Not Found',
                value: child.id ?? 'Not Found',
                dbType: child.type,
                instanceType: 'singleView',
              })) ?? [],
        })),
    };
  };

  // 싱글뷰
  const getInstanceSingleMenuList = (): BreadCrumbMenuInfo => {
    return {
      children: instances.value
        ?.find(
          (instance) =>
            DATABASE_SINGLE_VIEW_TYPES[instance.type!] &&
            currentInstance.value.multiView.id === instance.id,
        )
        ?.children?.map((instance) => ({
          text: instance.name ?? 'Not Found',
          value: instance.id ?? 'Not Found',
          dbType: instance.type,
          instanceType: 'singleView',
        })),
    };
  };

  const setInstanceInfo = async () => {
    const { query } = route;

    try {
      await getInstances();
      if (!instances.value || !query?.id) {
        throw Error('Instances Data is not defined');
      }

      if (props.dbInstanceType === 'singleView') {
        const foundMultiView = findMultiView(query.id as string);
        const singleViewGetters: FolderInstance =
          store.getters[`${props.dbType}SingleViewEnv/getSelectedInstanceInfo`];

        setCurrentInstance({
          multiView: {
            name: foundMultiView?.name ?? 'Not Found',
            id: foundMultiView?.id ?? 'Not Found',
          },
          singleView: {
            name: singleViewGetters.name ?? 'Not Found',
            id: singleViewGetters.instanceId ?? 'Not Found',
          },
          currentDbInstanceType: 'singleView',
        });
      } else if (props.dbInstanceType === 'multiView') {
        const multiViewGetters: FolderInstanceItem =
          store.getters[`${props.dbType}MultiViewEnv/getSelectedFolderInfo`];
        setCurrentInstance({
          multiView: {
            name: multiViewGetters.name ?? 'Not Found',
            id: multiViewGetters.folderId ?? 'Not Found',
          },
          currentDbInstanceType: 'multiView',
        });
      }
    } catch (error) {
      console.log(error);
      showErrorMsg(ctx, t('NOTI.PRODUCT.INVALID_ACCESS'));
      await router.push({
        path: '/database/instance',
      });
    }
  };

  watch(
    () => route.fullPath,
    async () => {
      if (props.mode !== 'instance') {
        return;
      }
      await setInstanceInfo();
    },
    { immediate: true },
  );

  return {
    instanceTitleByViewMode,
    currentInstance,
    instanceTypeButtons,
    getInstanceMultiMenuList,
    getInstanceSingleMenuList,
  };
};

// Dashboard
const useMenuDashboard = (
  props: Props,
  menuList: Ref<BreadCrumbMenuInfo | undefined>,
  isBreadCrumbModalLoading: Ref<boolean>,
) => {
  const { t } = useInternational();
  const route = useRoute();
  const dashboardListViewStore = useDashboardListViewStore();
  const { fetchDashboardList, updateFavoriteDashboardList } = dashboardListViewStore;
  const { dashboardList } = storeToRefs(dashboardListViewStore);
  const { monitoringDashboard } = storeToRefs(useDashboardViewStore());

  const CURRENT_DASHBOARD_ROOT_MENU = {
    [BASE_MENU_VALUE.INFRASTRUCTURE]: t('WORD.INFRASTRUCTURE'),
    [BASE_MENU_VALUE.KAFKA]: t('WORD.GNB.KAFKA'),
    [BASE_MENU_VALUE.CLOUD]: t('WORD.CLOUD'),
    [BASE_MENU_VALUE.KUBERNETES]: t('WORD.KUBERNETES'),
    [BASE_MENU_VALUE.APPLICATION]: t('WORD.APPLICATION'),
    [BASE_MENU_VALUE.DATABASE]: t('WORD.DATABASE'),
    [BASE_MENU_VALUE.ORACLE]: t('WORD.ORACLE'),
    [BASE_MENU_VALUE.POSTGRESQL]: t('WORD.POSTGRESQL'),
    [BASE_MENU_VALUE.MYSQL]: t('WORD.MYSQL'),
    [BASE_MENU_VALUE.SQLSERVER]: t('WORD.SQL_SERVER'),
    [BASE_MENU_VALUE.REDIS]: t('WORD.REDIS'),
    [BASE_MENU_VALUE.CUBRID]: t('WORD.CUBRID'),
    [BASE_MENU_VALUE.MONGODB]: t('WORD.MONGODB'),
    [BASE_MENU_VALUE.TIBERO]: t('WORD.TIBERO'),
    [BASE_MENU_VALUE.ALTIBASE]: t('WORD.ALTIBASE'),
    [BASE_MENU_VALUE.BUSINESS]: t('WORD.BUSINESS'),
    [BASE_MENU_VALUE.LOGS]: t('WORD.LOGS'),
    [BASE_MENU_VALUE.ALERT]: t('WORD.ALERT'),
  } as const;

  const currentRootMenu = computed(() => {
    return CURRENT_DASHBOARD_ROOT_MENU[`${route.query.rootMenu}`];
  });

  const getDashboardMenuList = (): BreadCrumbMenuInfo => {
    const rootMenu =
      route.query.rootMenu === BASE_MENU_VALUE.KUBERNETES ? 'k8s' : route.query.rootMenu;
    return {
      children: dashboardList.value
        ?.filter((dashboard) => {
          if (route.query.menuType === DASHBOARD_MENU_TYPE.ANALYSIS_DASHBOARD) {
            return dashboard.analysisMenuPaths?.includes(`${rootMenu}`);
          }
          return dashboard.menuPaths?.includes(`${rootMenu}`);
        })
        ?.map((dashboard) => ({
          text: dashboard.dashboardName,
          value: `${dashboard.dashboardId}`,
          path: `/dashboard/view/${dashboard.dashboardId}?rootMenu=${route.query.rootMenu}&menuType=${route.query.menuType}`,
          favoriteId: dashboard.favoriteId,
        })),
    };
  };

  const changeFavorite = async (menu: BreadCrumbMenuInfo) => {
    if (!menu.value) {
      throw Error('menu value is not defined');
    }

    try {
      isBreadCrumbModalLoading.value = true;

      await updateFavoriteDashboardList([Number(menu.value)]);
      await fetchDashboardList();

      menuList.value = getDashboardMenuList();
    } catch (error) {
      console.log(error);
    } finally {
      isBreadCrumbModalLoading.value = false;
    }
  };
  provide(BREAD_CRUMBS_CHANGE_FAVORITE_KEY, changeFavorite); // breadCrumbElementLink.setup.ts에서만 사용

  watch(
    () => dashboardList.value,
    () => {
      if (props.mode === 'dashboard') {
        menuList.value = getDashboardMenuList();
      }
    },
    {
      once: true,
    },
  );

  return { monitoringDashboard, currentRootMenu, getDashboardMenuList };
};

export const setup = (props: Props) => {
  const { t } = useInternational();
  const menuList = ref<BreadCrumbMenuInfo>();
  const currentBreadCrumbId = ref<string>();
  const isShowModal = ref(false);
  const isBreadCrumbModalLoading = ref(false);
  const currentTarget = ref<HTMLButtonElement | null>(null);
  const menuPosition = ref<MenuPosition>({ top: '0', left: '0' });

  const { filteredPaths, getSiblingMenuPath } = useMenuRoutes();
  const {
    instanceTitleByViewMode,
    currentInstance,
    instanceTypeButtons,
    getInstanceMultiMenuList,
    getInstanceSingleMenuList,
  } = useMenuInstances(props);
  const { monitoringDashboard, currentRootMenu, getDashboardMenuList } = useMenuDashboard(
    props,
    menuList,
    isBreadCrumbModalLoading,
  );

  const isMenuToggle = ref(false);
  const menuToggleIndex = ref<number | null | undefined>(null);

  const setMenuPosition = () => {
    const { top, left, height } = currentTarget.value!.getBoundingClientRect();
    menuPosition.value = {
      top: `${top + height}px` || '0',
      left: `${left}px` || '0',
    };
  };

  const initMenu = () => {
    currentBreadCrumbId.value = undefined;
    isShowModal.value = false;
    isMenuToggle.value = false;
    menuToggleIndex.value = null;
    menuList.value = undefined;
  };

  const setMenu = (
    target: HTMLButtonElement,
    i?: number,
    instanceType?: MenuMouseEvent['instanceType'],
  ) => {
    currentTarget.value = target;
    setMenuPosition();
    isShowModal.value = true;

    currentBreadCrumbId.value = target.dataset.breadCrumbId;

    if (props.mode === 'common') {
      menuList.value = getSiblingMenuPath(i);
    } else if (props.mode === 'instance') {
      if (instanceType === 'multiView') {
        menuList.value = getInstanceMultiMenuList();
      } else if (instanceType === 'singleView') {
        menuList.value = getInstanceSingleMenuList();
      }
    } else if (props.mode === 'dashboard') {
      menuList.value = getDashboardMenuList();
    }
  };

  const menuMouseOver = ({ e, i, instanceType }: MenuMouseEvent) => {
    if (isMenuToggle.value) return;

    const target = e.currentTarget as HTMLButtonElement;
    setMenu(target, i, instanceType);
  };

  const menuMouseClick = ({ e, i, instanceType }: MenuMouseEvent) => {
    if (menuToggleIndex.value === i) {
      initMenu();
    } else {
      const target = e.currentTarget as HTMLButtonElement;
      isMenuToggle.value = true;
      menuToggleIndex.value = i;
      setMenu(target, i, instanceType);
    }
  };

  const menuMouseLeave = (e: MouseEvent) => {
    const target = e.relatedTarget;
    if (
      target instanceof HTMLElement &&
      (isMenuToggle.value || target?.closest(`.${BREAD_CRUMBS_MODAL_CLASS}`))
    ) {
      return;
    }
    initMenu();
  };

  const menuClose = () => {
    initMenu();
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const breadCrumbsArea = document.querySelector('.app-view-name');
    const modalArea = document.querySelector(`.${BREAD_CRUMBS_MODAL_CLASS}`);

    if (breadCrumbsArea && modalArea) {
      if (!breadCrumbsArea.contains(target) && !modalArea.contains(target)) {
        menuClose();
      }
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    if (isShowModal.value) {
      setMenuPosition();
    }
  });

  const openSecondDepthMenu = () => {
    if (filteredPaths.value.length === 3) {
      nextTick(() => {
        const modalContent = document.querySelector('.route-paths-modal__content');
        const secondDepthMenu = filteredPaths.value[2];
        isMenuToggle.value = true;
        menuToggleIndex.value = 2;
        const target = document.querySelector(
          `[data-bread-crumb-id="${secondDepthMenu.value}"]`,
        ) as HTMLButtonElement;
        if (target && modalContent) {
          setMenu(target, 2);
          modalContent.scrollTop = 0;
        }
      });

      nextTick(() => {});
    }
  };

  watch(
    () => filteredPaths.value,
    (newPaths, oldPaths) => {
      if (
        newPaths.length === 3 &&
        (!oldPaths || newPaths[1] !== oldPaths[1]) &&
        isShowModal.value
      ) {
        openSecondDepthMenu();
      }
    },
    { deep: true },
  );

  onMounted(() => {
    const appHeader = document.querySelector('.app-header');

    if (appHeader) {
      resizeObserver.observe(appHeader);
    }

    document.addEventListener('click', handleOutsideClick);
  });

  onUnmounted(() => {
    resizeObserver.disconnect();
    document.removeEventListener('click', handleOutsideClick);
  });

  return {
    t,
    isShowModal,
    isBreadCrumbModalLoading,

    menuList,
    currentBreadCrumbId,
    filteredPaths,
    menuPosition,
    isMenuToggle,
    menuToggleIndex,

    instanceTitleByViewMode,
    currentInstance,
    instanceTypeButtons,

    monitoringDashboard,
    currentRootMenu,

    menuMouseOver,
    menuMouseClick,
    menuMouseLeave,
    menuClose,
    handleOutsideClick,
  };
};
