import { PLATFORM } from '@/common/define/common.define';
import { useRtmApi } from '@/common/utils/apiUtils';
import { getFilterAlertFilterControllerAxios } from '@/openapi/alert/api/alert-filter-controller-api';
import { getFilterNotificationHistoryControllerAxios } from '@/openapi/alert/api/notification-history-controller-api';
import {
  getFilterApplicationFilterControllerAxios,
  getTransactionFilterApplicationFilterControllerAxios,
} from '@/openapi/application/api/application-filter-controller-api';
import { getTraceFilterApplicationTransactionControllerAxios } from '@/openapi/application/api/application-transaction-controller-api';
import { getInstancesDatabaseFilterControllerAxios } from '@/openapi/database/api/database-filter-controller-api';
import { getInstanceGroupListDatabaseInstanceV7ControllerAxios } from '@/openapi/database/api/database-instance-v7-controller-api';
import { getHostFilterV7ListInfraFilterV7ControllerAxios } from '@/openapi/infra/api/infra-filter-v7-controller-api';
import { getFilterKubernetesFilterV7ControllerAxios } from '@/openapi/kubernetes/api/kubernetes-filter-v7-controller-api';
import { getLogFiltersLoggingViewV8ControllerAxios } from '@/openapi/log/api/logging-view-v8-controller-api';
import { getSearchModelsNdmMonitoringControllerAxios } from '@/openapi/ndm/api/ndm-monitoring-controller-api';
import { getReportFilterReportExportV8ControllerAxios } from '@/openapi/report/api/report-export-v8-controller-api';
import { getFiltersApplicationRumBrowserErrorControllerAxios } from '@/openapi/rum/api/application-rum-browser-error-controller-api';
import { getRumPageFilterApplicationRumPageControllerAxios } from '@/openapi/rum/api/application-rum-page-controller-api';
import { getServiceGroupListFilterXmServiceControllerAxios } from '@/openapi/service/api/xm-service-controller-api';
import { getFiltersUrlMonitoringControllerAxios } from '@/openapi/url/api/url-monitoring-controller-api';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getNpmFilterNpmOverviewControllerAxios } from '@/openapi/npm/api/npm-overview-controller-api';
import { getFiltersCloudFilterV8ControllerAxios } from '@/openapi/cloud/api/cloud-filter-v8-controller-api';

export type PlatformType = (typeof PLATFORM)[keyof typeof PLATFORM];

type ExtendPlatformType =
  | PlatformType
  | 'alert'
  | 'service'
  | 'logging'
  | 'networkDevice'
  | 'report'
  | 'dashboardExport'
  | '';

export interface FilterApiInfo {
  platform: ExtendPlatformType;
  menu?: string;
  frameName?: string;
}

export interface Filter {
  id: string;
  name: string;
}

export interface FilterData {
  key: Filter;
  values: FilterData[] | Filter[];
  type?: string;
  useSearch?: boolean;
  expand?: boolean;
}

type FilterControllerType = (request: any) => PromiseAxiosResponse<any>;

const NAME_MAP_MAPPER: Record<string, [string, string][]> = {
  // Filter List 카테고리명 매핑이 필요할 경우 이곳에 추가 후 하단 조건문에 조건식 추가
  'notification-history': [
    ['status', 'notification_status'],
    ['ruleType', 'notification_type'],
    ['group', 'alert_group'],
  ],
  'rum-browser-error-tracking': [['rumGroupId', 'rum_group']],
  'page-performance-monitoring': [['RumGroup', 'rum_group']],
};

export const isFilterData = (v: FilterData | Filter): v is FilterData =>
  typeof v === 'object' && 'values' in v && 'key' in v;
export const isFilter = (v: FilterData | Filter): v is Filter =>
  typeof v === 'object' && 'id' in v && 'name' in v;

/**
 * @param checkedFilterListData checkedFilterListData
 * @returns Record<string, string[]>
 * @description checkedFilterListData에서 top level key.id를 key로, nested한 id를 values로 하는 Record를 반환합니다.
 */
export const extractFilterIds = (checkedFilterListData: FilterData[]): Record<string, string[]> => {
  const extract = (data: FilterData[] | Filter[]) => {
    const flatIds: string[] = [];

    data.forEach((d: FilterData | Filter) => {
      if (isFilter(d)) {
        flatIds.push(d.id);
      } else if (isFilterData(d)) {
        flatIds.push(...extract(d.values));
      }
    });

    return flatIds;
  };

  const result = {};
  const keys = checkedFilterListData.map((f) => f.key.id);

  keys.forEach((k) => {
    result[k] = extract(checkedFilterListData.find((f) => f.key.id === k)?.values ?? []);
  });
  return result;
};

const getFilterController = (
  platform: ExtendPlatformType,
  menu?: string,
): FilterControllerType | null => {
  switch (platform) {
    case PLATFORM.INFRA:
      if (menu === 'npm') return getNpmFilterNpmOverviewControllerAxios;
      return getHostFilterV7ListInfraFilterV7ControllerAxios;
    case PLATFORM.KUBE:
      return getFilterKubernetesFilterV7ControllerAxios;
    case PLATFORM.APP:
      switch (menu) {
        case 'application':
          return getFilterApplicationFilterControllerAxios;
        case 'trace':
          return getTraceFilterApplicationTransactionControllerAxios;
        case 'transaction':
          return getTransactionFilterApplicationFilterControllerAxios;
        case 'rum-browser-error-tracking':
          return getFiltersApplicationRumBrowserErrorControllerAxios;
        case 'page-performance-monitoring':
          return getRumPageFilterApplicationRumPageControllerAxios;
        case 'url-monitoring':
          return getFiltersUrlMonitoringControllerAxios;
        default:
          break;
      }
      break;
    case PLATFORM.DB:
      if (menu === 'database') return getInstancesDatabaseFilterControllerAxios;
      return getInstanceGroupListDatabaseInstanceV7ControllerAxios;
    case PLATFORM.CLOUD:
      return getFiltersCloudFilterV8ControllerAxios;
    case 'alert':
      if (menu === 'alert-list') return getFilterAlertFilterControllerAxios;
      return getFilterNotificationHistoryControllerAxios;
    case 'service':
      return getServiceGroupListFilterXmServiceControllerAxios;
    case 'networkDevice':
      return getSearchModelsNdmMonitoringControllerAxios;
    case 'logging':
      return getLogFiltersLoggingViewV8ControllerAxios;
    case 'dashboardExport':
      return getReportFilterReportExportV8ControllerAxios;
    default:
      return null;
  }
  return null;
};

const processFilterData = (menu, rawData) => {
  const NameMap = new Map(NAME_MAP_MAPPER[menu] ?? []);

  if (menu === 'host') {
    return rawData.length > 1 ? [{ ...rawData[1], useSearch: true }, rawData[0]] : [];
  }

  return rawData.map((filter) => ({
    ...filter,
    key: {
      id: filter.key?.id ?? '',
      name: NameMap.get(filter.key?.name ?? '') ?? filter.key?.name ?? '',
    },
  }));
};

export const useFilterListStore = defineStore('filterList', () => {
  const { callApi } = useRtmApi();
  const filterListData = ref<FilterData[]>([]);
  const checkedFilterListData = ref<FilterData[]>([]);
  const isFilterLoading = ref(false);

  const fetchFilterList = async (parameter) => {
    filterListData.value = [];
    const { platform, menu, frameName } = parameter;

    const controller = getFilterController(platform, menu);
    if (!menu || !controller) return;

    isFilterLoading.value = true;

    const params =
      platform !== PLATFORM.CLOUD
        ? { menu }
        : {
            cloud: 'aws',
            cloudBasicViewService: menu.toLowerCase(),
          };

    try {
      const { data: rawData = [] } = await callApi({
        fn: controller,
        params,
        isTimeout: false,
        frameName,
      });

      filterListData.value = processFilterData(menu, rawData);
    } catch (e) {
      console.log(e);
    } finally {
      isFilterLoading.value = false;
    }
  };

  const updateCheckedFilterList = (checkedItems: FilterData[]) => {
    checkedFilterListData.value = checkedItems;
  };

  return {
    filterListData,
    checkedFilterListData,
    isFilterLoading,
    fetchFilterList,
    updateCheckedFilterList,
  };
});
