import { ref } from 'vue';
import { defineStore } from 'pinia';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { UseRolePermission, useRolePermission } from '@/common/permission/permission.utils';
import { convertRequestTime, utcZeroTimeToStandardTime } from '@/common/utils/commonUtils';
import {
  checkPopupTimeNoticeControllerAxios,
  deleteNoticeControllerAxios,
  getEditInfoNoticeControllerAxios,
  getNoticeControllerAxios,
  getNoticeListNoticeControllerAxios,
  saveNoticeControllerAxios,
  testEmailNoticeControllerAxios,
  updateNoticeControllerAxios,
} from '@/openapi/notice/api/notice-controller-api';
import {
  IdInfo,
  NoticeDetailView,
  NoticeInfoResponse,
  NoticeOverView,
  NoticeOverViewEmailRequest,
  PopupPeriod,
  PostPopupInfoResponse,
} from '@/openapi/notice/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { makeRows } from '@/common/utils/gridUtils';
import { noticeListColumns } from '@/config/views/management/notice/noticeList.setup';
import { useInternational } from '@/common/locale';
import { isDuplicateNameError } from '@/common/utils/error.utils';
import dayjs from 'dayjs';
import { store } from '@/common/store';
import { RoleIdMap } from '@/common/auth/auth.defines';
import { webStorageController } from '@/common/utils/webStorage.util';

type ResultMessage = 'success' | 'failure';
export const useApi = () => {
  const { t } = useInternational();

  const { isPermissionDenied } = useRolePermission();
  const PERMISSION_DENIED = 'PERMISSION_DENIED';

  const refreshTime = ref<string>('');

  const checkPermissionDeny = (type: UseRolePermission['type'], showError = true) => {
    return isPermissionDenied({
      type,
      rolePermissionKey: ROLE_PERMISSION_KEY.SETTING.SETTING_MANAGEMENT_NOTICE,
      showError,
    });
  };
  const getErrorMessage = (e: any) => {
    if (e?.message === PERMISSION_DENIED) {
      return t('NOTI.API.NOT_PERMISSION');
    }
    if (isDuplicateNameError(e)) {
      return t('ERROR.API.DuplicateNameException');
    }
    return t('NOTI.COMMON_CRUD.SAVE_FAIL');
  };

  // NoticeList
  const getNoticeList = async ({
    frameName,
  }: {
    frameName: string;
  }): Promise<{ data: NoticeOverView[]; refreshTime: string; error?: any }> => {
    try {
      const { data } = await getNoticeListNoticeControllerAxios({
        frameName,
      });
      refreshTime.value = convertRequestTime();

      return {
        data: data?.data ?? [],
        refreshTime: refreshTime.value,
      };
    } catch (e) {
      console.log(e);
      return {
        data: [],
        refreshTime: refreshTime.value,
        error: getErrorMessage(e),
      };
    }
  };
  // NoticeDetail
  const getNoticeDetailInfo = async ({
    noticeId,
    frameName,
  }: {
    noticeId: number;
    frameName: string;
  }): Promise<{ data: NoticeDetailView[]; refreshTime?: string; error?: any }> => {
    try {
      const { data } = await getNoticeControllerAxios({
        noticeId,
        frameName,
      });

      return {
        data: data?.data ?? [],
      };
    } catch (e) {
      console.log(e);
      return {
        data: [],
        error: getErrorMessage(e),
      };
    }
  };
  // NoticeEditInfo
  const getNoticeEditInfo = async ({
    noticeId,
    frameName,
  }: {
    noticeId: number;
    frameName: string;
  }): Promise<{ data: NoticeInfoResponse[]; refreshTime?: string; error?: any }> => {
    try {
      const { data } = await getEditInfoNoticeControllerAxios({
        noticeId,
        frameName,
      });

      return {
        data: data?.data ?? [],
      };
    } catch (e) {
      console.log(e);
      return {
        data: [],
        error: getErrorMessage(e),
      };
    }
  };

  const testEmailNotice = async ({
    noticeId,
    frameName,
  }: {
    noticeId: number;
    frameName: string;
  }): Promise<{ resultMsg: ResultMessage; error?: any }> => {
    try {
      const { data } = await testEmailNoticeControllerAxios({
        noticeId,
        frameName,
      });
      return {
        resultMsg: data?.status === 'success' ? 'success' : 'failure',
        error: data?.status === 'failure' ? getErrorMessage(null) : null,
      };
    } catch (e: any) {
      console.log(e);
      return {
        resultMsg: 'failure',
        error: getErrorMessage(e),
      };
    }
  };

  const addNotice = async ({
    request,
    frameName,
  }: {
    request: any;
    frameName: string;
  }): Promise<{ resultMsg: ResultMessage; error?: any }> => {
    try {
      if (checkPermissionDeny('add', false)) {
        throw new Error(PERMISSION_DENIED);
      }

      const { data } = await saveNoticeControllerAxios({
        request,
        frameName,
      });
      return {
        resultMsg: data?.status === 'success' ? 'success' : 'failure',
        error: data?.status === 'failure' ? getErrorMessage(null) : null,
      };
    } catch (e: any) {
      console.log(e);
      return {
        resultMsg: 'failure',
        error: getErrorMessage(e),
      };
    }
  };
  const editNotice = async ({
    noticeId,
    request,
    frameName,
  }: {
    noticeId: number;
    request: any;
    frameName: string;
  }): Promise<{ resultMsg: ResultMessage; error?: any }> => {
    try {
      if (checkPermissionDeny('edit', false)) {
        throw new Error(PERMISSION_DENIED);
      }

      const { data } = await updateNoticeControllerAxios({
        noticeId,
        request,
        frameName,
      });
      return {
        resultMsg: data?.status === 'success' ? 'success' : 'failure',
        error: data?.status === 'failure' ? getErrorMessage(null) : null,
      };
    } catch (e: any) {
      console.log(e);
      return {
        resultMsg: 'failure',
        error: getErrorMessage(e),
      };
    }
  };

  const deleteNotice = async ({
    noticeIds,
    frameName,
  }: {
    noticeIds: Array<IdInfo>;
    frameName: string;
  }): Promise<{ resultMsg: ResultMessage; error?: any }> => {
    try {
      if (checkPermissionDeny('delete', false)) {
        throw new Error(PERMISSION_DENIED);
      }

      const { data } = await deleteNoticeControllerAxios({
        noticeIds,
        frameName,
      });
      return {
        resultMsg: data?.status === 'success' ? 'success' : 'failure',
        error: data?.status === 'failure' ? getErrorMessage(null) : null,
      };
    } catch (e) {
      console.log(e);
      return {
        resultMsg: 'failure',
        error: getErrorMessage(e),
      };
    }
  };
  // PopupCheck
  const fetchPopupList = async ({
    frameName,
  }: {
    frameName: string;
  }): Promise<{ data: PostPopupInfoResponse[]; refreshTime?: string; error?: any }> => {
    try {
      const { data } = await checkPopupTimeNoticeControllerAxios({
        frameName,
      });

      return {
        data: data?.data ?? [],
        refreshTime: data.requestTime ? utcZeroTimeToStandardTime(data.requestTime) : '',
      };
    } catch (e) {
      console.log(e);
      return {
        data: [],
        refreshTime: '',
        error: getErrorMessage(e),
      };
    }
  };
  return {
    checkPermissionDeny,
    getNoticeList,
    getNoticeDetailInfo,
    getNoticeEditInfo,
    addNotice,
    editNotice,
    deleteNotice,
    testEmailNotice,
    fetchPopupList,
  };
};
interface NoticeList {
  noticeChannel?: string;
  emailChannel?: boolean;
  popupChannel?: boolean;
  emailRequest?: NoticeOverViewEmailRequest;
  emailRequestTimezone?: string;
  emailSendTime?: string;
  id?: number;
  name?: string;
  noticeStartDate?: string;
  popupNoticeStartDate?: string;
  popupPeriod?: PopupPeriod;
  popupNoticeEndDate?: string;
  noticeStartTimezone?: string;
  registrationTime?: string;
  temporarySaved?: boolean;
  title?: string;
}

export const useNoticeStore = defineStore('useNoticeStore', () => {
  const { t } = useInternational();
  const {
    getNoticeList,
    getNoticeDetailInfo,
    getNoticeEditInfo,
    checkPermissionDeny,
    fetchPopupList,
  } = useApi();
  const { role: roleId } = store.getters['myInfo/getAccountInfo'];
  const isShowNoticeWindowPopup = ref<boolean>(false);

  const hasPermission = {
    add: !checkPermissionDeny('add', false) && roleId < RoleIdMap.User,
    edit: !checkPermissionDeny('edit', false) && roleId < RoleIdMap.User,
    delete: !checkPermissionDeny('delete', false) && roleId < RoleIdMap.User,
  };

  const popupInfo = ref<PostPopupInfoResponse[]>([]);
  const noticeListRows = ref<NoticeList[]>([]);
  const filterData = ref<NoticeList[]>([]);
  const refreshTime = ref<string>('');
  const isFailRequestTime = ref<boolean>(false);
  const selectedNameList = ref<any>([]);
  const refresh = async () => {
    const data = await getNoticeList({
      frameName: FRAME_NAMES.SETTING_NOTICE.GET_NOTICE_LIST,
    });

    const formatDate = (date) =>
      date ? dayjs(utcZeroTimeToStandardTime(date)).format('YYYY-MM-DD HH:mm') : '';

    filterData.value = data.data.map((item) => {
      const email = item.emailChannel ? t('WORD.EMAIL') : '';

      const popup = item.popupChannel ? t('WORD.POPUP') : '';

      return {
        ...item,
        noticeChannel: [email, popup].filter((channel) => channel !== '').join(','),
        noticeStartDate: formatDate(item.noticeStartDate),
        emailSendTime: formatDate(item.emailSendTime),
        popupNoticeStartDate: formatDate(item.popupPeriod?.fromTime),
        popupNoticeEndDate: formatDate(item.popupPeriod?.toTime),
      };
    });

    isFailRequestTime.value = !!data.error;

    noticeListRows.value = makeRows(filterData.value, noticeListColumns);
    refreshTime.value = data.refreshTime;
  };
  const isOverDate = ref(false);
  const today = dayjs().tz();

  const noticeDetailInfo = ref<NoticeDetailView>({ content: '' });
  const noticeEditInfo = ref<NoticeInfoResponse>({});
  const getDetailInfo = async (noticeId: number) => {
    const data = await getNoticeDetailInfo({
      noticeId,
      frameName: FRAME_NAMES.SETTING_NOTICE.GET_NOTICE_DETAIL,
    });
    const resData = data.data[0];
    selectedNameList.value = resData.recipients?.map((item) => item.name);
    resData.content = resData.content ? resData.content : '';

    isOverDate.value = dayjs(utcZeroTimeToStandardTime(resData.noticeStartDate)) < today;
    noticeDetailInfo.value = resData;
  };
  const getEditInfo = async (noticeId: number) => {
    const data = await getNoticeEditInfo({
      noticeId,
      frameName: FRAME_NAMES.SETTING_NOTICE.GET_NOTICE_EDIT_DETAIL,
    });
    const resData = data.data[0];
    noticeEditInfo.value = resData;
  };

  const getPopupInfo = async () => {
    const data = await fetchPopupList({
      frameName: FRAME_NAMES.SETTING_NOTICE.GET_POPUP_CHECK,
    });
    popupInfo.value = data.data;
  };

  const checkNotShowNoticePopupFunc = async () => {
    const popupData = JSON.parse(
      webStorageController.getItem({
        type: 'local',
        key: 'noticePopupInfo',
      }) || '{}',
    );
    const isNotShowPopupCheck = popupData.isShowPopupCheck;

    const hasIdsNotInPopup =
      Object.keys(popupData).length > 0
        ? popupInfo.value.some((item) => !popupData.popupInfo.some((popup) => popup.id === item.id))
        : true;

    let isChangeData = false;
    if (!hasIdsNotInPopup) {
      popupInfo.value.forEach((item) => {
        const matchedPopup = popupData.popupInfo.find((popup) => popup.id === item.id);
        if (matchedPopup) {
          if (
            item?.popupPeriod?.fromTime !== matchedPopup.fromTime ||
            item?.popupPeriod?.toTime !== matchedPopup.toTime
          ) {
            isChangeData = true;
          }
        }
      });
    }

    if (
      ((isNotShowPopupCheck && !hasIdsNotInPopup) || popupInfo.value.length === 0) &&
      !isChangeData
    ) {
      isShowNoticeWindowPopup.value = false;
    }
  };

  return {
    hasPermission,
    refreshTime,
    isFailRequestTime,
    refresh,
    noticeListRows,
    getDetailInfo,
    getEditInfo,
    noticeDetailInfo,
    noticeEditInfo,
    selectedNameList,
    isOverDate,
    checkPermissionDeny,
    isShowNoticeWindowPopup,
    checkNotShowNoticePopupFunc,
    getPopupInfo,
    popupInfo,
  };
});
