import {
  CustomFilterOption,
  FilterItem,
  FilterSelectedValues,
} from '@/common/components/molecules/filterSearch/filterSearch.type';
import { ComputedRef, ref } from 'vue';
import { filterFunctionByOperator } from '@/common/components/molecules/filterSearch/filterSearch.utils';
import { CustomColumn } from '@/common/utils';
import {
  GridRowInstance,
  GridColumnInstance,
  GridRawRowDataType,
} from '@/common/components/molecules/modernGrid/modernGrid.types';

export const useEvGridFilterSearch = <Row extends unknown>({
  columns,
  filterItems,
}: {
  columns: ComputedRef<CustomColumn[] | Readonly<CustomColumn[]>>;
  filterItems: FilterItem[];
}) => {
  const filterSearchResultMV = ref<FilterSelectedValues>();

  const customFilterFnByKey: { [key: string]: FilterItem['customFilterFn'] } = filterItems.reduce(
    (acc, item) => {
      acc[item.key.id] = item?.customFilterFn;
      return acc;
    },
    {} as { [key: string]: FilterItem['customFilterFn'] },
  );

  const commonFilterCondition = (
    row: Row,
    token: {
      key: string;
      operator: string;
      value: string;
    },
    isTreeGrid: boolean,
  ): boolean => {
    const targetColumnIndex = columns?.value?.findIndex((column) => column.field === token.key);
    if (targetColumnIndex < 0) {
      return false;
    }

    const { type } = columns?.value?.[targetColumnIndex];
    const multi = filterItems?.find((item) => item.key.id === token.key)?.values?.multi;
    const searchValues: string[] = multi ? token.value.split(',') : [token.value];
    const targetValue = isTreeGrid ? row[token.key] : row[targetColumnIndex];

    return filterFunctionByOperator({
      targetValue,
      operator: token.operator,
      searchValues,
      type,
    });
  };

  // externalCustom : 외부에서 추가된 customFilterFn
  // internalCustom : FilterItems에서 받은 customFilterFn
  const evaluateToken = ({
    row,
    token,
    isTreeGrid,
    externalCustomOption,
  }: {
    row: any;
    token: any;
    isTreeGrid: boolean;
    externalCustomOption: CustomFilterOption[] | undefined;
  }): boolean => {
    const externalCustom = externalCustomOption?.find((option) => option.id === token.key);
    if (externalCustom) {
      return externalCustom.filterFn(row, token);
    }

    const internalCustom: FilterItem['customFilterFn'] | undefined = customFilterFnByKey[token.key];
    if (internalCustom) {
      const targetColumnIndex = columns?.value?.findIndex((column) => column.field === token.key);
      if (targetColumnIndex < 0) {
        return false;
      }

      const cellValue = isTreeGrid ? row[token.key] : row[targetColumnIndex];
      return internalCustom(cellValue, token);
    }

    return commonFilterCondition(row, token, isTreeGrid);
  };

  const filterGridByFilterSearch = (
    rows,
    result: FilterSelectedValues | undefined,
    externalCustomOption?: CustomFilterOption[],
  ): any[] => {
    if (!rows || !result?.tokens?.length) {
      return rows ?? [];
    }

    return rows?.filter((row) => {
      if (result.logicalOperator === 'AND') {
        return result.tokens.every((token) =>
          evaluateToken({
            row,
            token,
            externalCustomOption,
            isTreeGrid: false,
          }),
        );
      }

      return result.tokens.some((token) =>
        evaluateToken({
          row,
          token,
          externalCustomOption,
          isTreeGrid: false,
        }),
      );
    });
  };

  const filterTree = (
    rows,
    filterValues: FilterSelectedValues,
    parentExpand = true,
    externalCustomOption?: CustomFilterOption[],
  ): void => {
    rows.forEach((node) => {
      const isMatchedNode =
        filterValues?.logicalOperator === 'AND'
          ? filterValues.tokens.every((token) =>
              evaluateToken({
                row: node,
                token,
                externalCustomOption,
                isTreeGrid: true,
              }),
            )
          : filterValues.tokens.some((token) =>
              evaluateToken({
                row: node,
                token,
                externalCustomOption,
                isTreeGrid: true,
              }),
            );

      if (node.children) {
        filterTree(node.children, filterValues, parentExpand && node.expand);
      }

      // 자식 노드 중 하나라도 조건에 맞는 node가 있을 경우 부모 노드 show 처리
      const isIncludeNotFilteredChild = !!(node.children ?? []).some((child) => child.show);
      node.show = isMatchedNode || isIncludeNotFilteredChild;
      node.isFilter = !node.show;

      if (!node.children?.length) return;

      if (isMatchedNode) {
        // 부모 노드가 show인 경우, 자식 모두 show 처리
        const isExpand = parentExpand && node.expand;
        node.children.forEach((child) => {
          child.show = isExpand;
          child.isFilter = false;
        });
      } else {
        // 부모 노드가 show가 아닌 경우, 조건에 맞는 자식만 show 처리
        const isExpand = parentExpand && node.expand;
        node.children.forEach((child) => {
          child.show = isExpand && !child.isFilter;
        });
      }
    });
  };

  const initTree = (rows) => {
    rows.forEach((node) => {
      node.show = node.parent ? node.parent.expand && node.parent.show : true;
      node.isFilter = false;

      if (node.children?.length) {
        initTree(node.children);
      }
    });
  };

  const filterTreeGridByFilterSearch = (rows, result: FilterSelectedValues | undefined) => {
    initTree(rows);
    if (result?.tokens.length) {
      filterTree(rows, result);
    }

    return rows;
  };

  const changeStateObjectRowsByFilterSearch = (rows, result: FilterSelectedValues | undefined) => {
    if (!result?.tokens.length) {
      return rows.map((node) => ({ ...node, searched: false }));
    }

    return rows.map((node) => {
      const searched =
        result?.logicalOperator === 'AND'
          ? result.tokens.every((token) => commonFilterCondition(node, token, true))
          : result?.tokens.some((token) => commonFilterCondition(node, token, true));

      return {
        ...node,
        searched,
      };
    });
  };

  return {
    filterSearchResultMV,
    filterGridByFilterSearch,
    filterTreeGridByFilterSearch,
    changeStateObjectRowsByFilterSearch,
  };
};

export const useModernGridFilterSearch = <RowDataType extends GridRawRowDataType>({
  columns,
  filterItems,
}: {
  columns: ComputedRef<GridColumnInstance<RowDataType>[] | undefined>;
  filterItems: FilterItem[];
}) => {
  const filterSearchResultMV = ref<FilterSelectedValues>();

  const commonFilterCondition = (
    row: GridRowInstance<RowDataType>,
    token: {
      key: string;
      operator: string;
      value: string;
    },
  ): boolean => {
    const column = columns.value?.find(({ id }) => token.key === id);
    if (!column) return false;

    const { type = 'string', filterType } = column.columnDef.meta || {};
    const multi = filterItems?.find((item) => item.key.id === token.key)?.values?.multi;
    const searchValues: string[] = multi ? token.value.split(',') : [token.value];
    const rawValue = row.original[token.key];
    const formattedValue = row.getValue(token.key) || rawValue;

    const rawFilterResult = filterFunctionByOperator({
      targetValue: rawValue,
      operator: token.operator,
      searchValues,
      type,
    });

    const formattedFilterResult = filterFunctionByOperator({
      targetValue: formattedValue,
      operator: token.operator,
      searchValues,
      type,
    });

    let filterResult = formattedFilterResult;
    if (filterType === 'onlyRaw') {
      filterResult = rawFilterResult;
    } else if (filterType === 'both') {
      filterResult = rawFilterResult || formattedFilterResult;
    }
    return filterResult;
  };

  const customFilterFnByKey: { [key: string]: FilterItem['customFilterFn'] } = filterItems.reduce(
    (acc, item) => {
      acc[item.key.id] = item?.customFilterFn;
      return acc;
    },
    {} as { [key: string]: FilterItem['customFilterFn'] },
  );

  // externalCustom : 외부에서 추가된 customFilterFn
  // internalCustom : FilterItems에서 받은 customFilterFn
  const evaluateToken = ({
    row,
    token,
    externalCustomOption,
  }: {
    row: GridRowInstance<RowDataType>;
    token: {
      key: string;
      operator: string;
      value: string;
    };
    externalCustomOption: CustomFilterOption[] | undefined;
  }): boolean => {
    const externalCustom = externalCustomOption?.find((option) => option.id === token.key);
    if (externalCustom) {
      return externalCustom.filterFn(row.original, token);
    }

    const internalCustom: FilterItem['customFilterFn'] | undefined = customFilterFnByKey[token.key];
    if (internalCustom) {
      return internalCustom(row.original[token.key], token);
    }

    return commonFilterCondition(row, token);
  };

  const filterGridByFilterSearch = (
    row: GridRowInstance<RowDataType>,
    filterValue: FilterSelectedValues | undefined,
    externalCustomOption?: CustomFilterOption[],
  ): boolean => {
    const isValidFilter = typeof filterValue === 'object' && filterValue !== null;
    const { tokens, logicalOperator } = filterValue || {};
    if (isValidFilter && tokens?.length) {
      return logicalOperator === 'AND'
        ? tokens.every((token) => evaluateToken({ row, token, externalCustomOption }))
        : tokens.some((token) => evaluateToken({ row, token, externalCustomOption }));
    }
    return true;
  };

  return {
    filterSearchResultMV,
    filterGridByFilterSearch,
  };
};
