/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  ApiResponseFilterItem,
  ApiResponseNpmOverviewResponse,
  ApiResponseNpmTopologyResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getNpmFilterNpmOverviewControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseFilterItem> => {
  const localVarPath = '/api/v7/npm/monitoring/filter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getNpmOverviewNpmOverviewControllerAxios = ({
  networkObjectIds,
  fromTime,
  periodType,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  networkObjectIds: Array<string>;
  fromTime?: string;
  periodType?:
    | 'p10m'
    | 'p10s'
    | 'p12h'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p1s'
    | 'p30m'
    | 'p30s'
    | 'p3h'
    | 'p3m'
    | 'p3s'
    | 'p5d'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseNpmOverviewResponse> => {
  const localVarPath = '/api/v7/npm/monitoring/overview';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      networkObjectIds,
      periodType,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getNpmTopologyNpmOverviewControllerAxios = ({
  fromTime,
  networkObjectIds,
  periodType,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  networkObjectIds?: Array<string>;
  periodType?:
    | 'p10m'
    | 'p10s'
    | 'p12h'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p1s'
    | 'p30m'
    | 'p30s'
    | 'p3h'
    | 'p3m'
    | 'p3s'
    | 'p5d'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseNpmTopologyResponse> => {
  const localVarPath = '/api/v7/npm/monitoring/topology';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      networkObjectIds,
      periodType,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getNpmFilterNpmOverviewControllerAxios,
  getNpmOverviewNpmOverviewControllerAxios,
  getNpmTopologyNpmOverviewControllerAxios,
};
