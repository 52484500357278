const MAIN_FRAMES = {
  INSTANCE_VIEW: 'Instance View',
  INSTANCE_DETAIL: 'Instance Detail',
  INSTANCE_SESSION_DETAIL: 'Instance Session Detail',
  INSTANCE_SQL_DETAIL: 'Instance SQL Detail',
  REDIS_INSTANCE_DETAIL: 'Redis Instance Detail', // NOTE: Redis, Cubrid 만 별도 API 호출
  CUBRID_INSTANCE_DETAIL: 'Cubrid Instance Detail', // NOTE: Redis, Cubrid 만 별도 API 호출
  INSTANCE_CLUSTER_DETAIL: 'Instance Cluster Detail',
  DATABASE_ALERT_DETAIL: 'Alert Detail - Database',
  DB_METRIC: 'DB Metric',

  // MFC - single, multi view
  MYSQL_SINGLE_VIEW: 'MySQL - Single View',
  MYSQL_MULTI_VIEW: 'MySQL - Multi View',
  ORACLE_MULTI_VIEW: 'Oracle - Multi View',
  POSTGRESQL_SINGLE_VIEW: 'PostgreSQL - Single View',
  POSTGRESQL_MULTI_VIEW: 'PostgreSQL - Multi View',
  SQLSERVER_SINGLE_VIEW: 'SQL Server - Single View',
  SQLSERVER_MULTI_VIEW: 'SQL Server - Multi View',

  PLAN_DIFF_WINDOW: 'Plan Diff Window',

  SESSION_TAB: 'Session Tab',
  SESSION_DETAIL_WINDOW: 'Session Detail Window',
  SESSION_MANAGER_WINDOW: 'Session Manager Window',

  SQL_ELAPSED_LIST_WINDOW: 'SQL Elapsed List Window',
  SQL_DETAIL_WINDOW: 'SQL Detail Window',

  FUNCTION_DETAIL_WINDOW: 'Function Detail Window',
  USER_ENVIRONMENT: 'User Environment',
  PARAMETER_DETAIL: 'Parameter Detail',
} as const;

export const DATABASE_FRAME_NAMES = {
  INSTANCE_VIEW: {
    FILTERS: `${MAIN_FRAMES.INSTANCE_VIEW}/Filters`,
    INSTANCE_LIST: `${MAIN_FRAMES.INSTANCE_VIEW}/Database List`,
    FAVORITE_INSTANCE_UPDATE: `${MAIN_FRAMES.INSTANCE_VIEW}/[Update] Favorite Database`,
  },
  INSTANCE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.INSTANCE_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.INSTANCE_DETAIL}/Metric`,
    ACTIVE_SESSION: `${MAIN_FRAMES.INSTANCE_DETAIL}/Active Session`,
    EVENT: `${MAIN_FRAMES.INSTANCE_DETAIL}/Event`,
    SQL_LIST: `${MAIN_FRAMES.INSTANCE_DETAIL}/SQL List`,
    LOCK_INFO: `${MAIN_FRAMES.INSTANCE_DETAIL}/Lock Info`,
    META_LOCK_INFO: `${MAIN_FRAMES.INSTANCE_DETAIL}/Meta Lock Info`,
    ALERT: `${MAIN_FRAMES.INSTANCE_DETAIL}/Alert`,
    PARAMETER: `${MAIN_FRAMES.INSTANCE_DETAIL}/Parameter`,
    LOGS: `${MAIN_FRAMES.INSTANCE_DETAIL}/Logs`,
    FULL_TEXT: `${MAIN_FRAMES.INSTANCE_DETAIL}/SQL Full Text`,
    CLOUD_EVENT: `${MAIN_FRAMES.INSTANCE_DETAIL}/Cloud Event`,
  },
  INSTANCE_SESSION_DETAIL: {
    EVENT_INFO: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Event Info`,
    FULL_TEXT: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Full Text`,
    HISTORY: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Active Session History`,
    INFORMATION: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Information`,
    KILL_SESSION: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Kill Session`,
    PLAN: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Plan`,
    BIND: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Bind`,
    PARAMETER_LIST: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Parameter List`,
    PERFORMANCE_DETAILS: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Session Performance Details`,
    SESSION_METRIC: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/Session Metric`,
    SQL_METRIC: `${MAIN_FRAMES.INSTANCE_SESSION_DETAIL}/SQL Metric`,
  },
  INSTANCE_SQL_DETAIL: {
    EVENT_INFO: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Event Info`,
    FULL_TEXT: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Full Text`,
    HISTORY: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/History`,
    INFORMATION: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Information`,
    PLAN: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Plan`,
    PLAN_HASH_LIST: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Plan Hash List`,
    CHILD_NUMBER_LIST: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Child Number List`,
    PARAMETER_LIST: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Parameter List`,
    TREND: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend`,
    // NOTE: 쿼리개선시 한번에 api 호출하므로 아래의 key 들 필요없음
    TREND_SQL: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend By SQL Stat`,
    TREND_SESSION: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend By Session Stat`,
    TREND_BUFFER_GETS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Buffer Gets`,
    TREND_CPU_TIME: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - CPU Time`,
    TREND_DISK_READS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Disk Reads`,
    TREND_ELAPSED_TIME: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Elapsed Time`,
    TREND_EXECUTIONS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Executions`,
    TREND_USER_CALLS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - User Calls`,
    TREND_RECURSIVE_CALLS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Recursive Calls`,
    TREND_WORKER_TIME: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Worker Time`,
    TREND_LOGICAL_READS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Logical Reads`,
    TREND_PHYSICAL_READS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Physical Reads`,
    TREND_LATENCY: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Latency`,
    TREND_ROWS_EXAMINED: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Rows Examined`,
    TREND_ROWS_SENT: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Rows Sent`,
    TREND_TOTAL_TIME: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Total Time`,
    TREND_SHARED_BLKS_HIT: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Shared Blocks Hit`,
    TREND_SHARED_BLKS_READ: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Shared Blocks Read`,
    TREND_EXECUTE_TIME: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Execute Time`,
    TREND_READ_PAGE: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Read Page`,
    TREND_GET_PAGE: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Get Page`,
    TREND_EXECUTE_SUCCESS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Execute Success`,
    TREND_CALLS: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Trend - Calls`,
    BIND: `${MAIN_FRAMES.INSTANCE_SQL_DETAIL}/Bind`,
  },
  REDIS_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/Metric`,
    CLIENT_LIST: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/Client List`,
    COMMAND_STAT: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/Command Stat`,
    KEY_BROWSER: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/Key Browser`,
    KEY_BROWSER_STRING: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/key Browser > String`,
    KEY_BROWSER_LIST: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/key Browser > List`,
    KEY_BROWSER_SET: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/key Browser > Set`,
    KEY_BROWSER_SORTED_SET: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/key Browser > Sorted Set`,
    KEY_BROWSER_STREAM: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/key Browser > Stream`,
    KEY_BROWSER_HASH: `${MAIN_FRAMES.REDIS_INSTANCE_DETAIL}/key Browser > Hash`,
  },
  CUBRID_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.CUBRID_INSTANCE_DETAIL}/Information`,
  },
  INSTANCE_CLUSTER_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.INSTANCE_CLUSTER_DETAIL}/Information`,
    MAP: `${MAIN_FRAMES.INSTANCE_CLUSTER_DETAIL}/Map`,
  },
  DATABASE_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.DATABASE_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.DATABASE_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.DATABASE_ALERT_DETAIL}/Clear System Alert`,
  },
  DB_METRIC: {
    NAMES: `${MAIN_FRAMES.DB_METRIC}/DB Metric Name`,
    METRIC: `${MAIN_FRAMES.DB_METRIC}/DB Metric`,
    METRIC_I1M: `${MAIN_FRAMES.DB_METRIC}/DB Metric 1 minute interval`,
    METRIC_I5S: `${MAIN_FRAMES.DB_METRIC}/DB Metric 5 second interval`,
  },

  MYSQL_SINGLE_VIEW: {
    ADMIN_REFERENCE_ALERT_LOGS: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Alert Logs`,
    ADMIN_REFERENCE_TEMP_USAGE_CHART: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Temp Usage Chart`,
    ADMIN_REFERENCE_OPEN_TABLES: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Open Tables`,
    ADMIN_REFERENCE_PARAMETER: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Parameter`,
    ADMIN_REFERENCE_TOP_TABLESPACE_SIZE: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Admin Reference > Top Tablespace Size`,
    OVERVIEW: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview`,
    OVERVIEW_ALERT: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview > Alert`,
    OVERVIEW_REPLICATION: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview > Replication`,
    OVERVIEW_SORTING: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview > Sorting`,
    OVERVIEW_REDO_LOG: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Overview > Redo Log File Size`,
    SCAN_TYPE: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Scan Type`,
    SLOW_QUERY: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Slow Query`,
    STATICS_EVENTS_TOP_DIFF_STATISTICS: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Statics & Events > Top Diff Statistics`,
    STATICS_EVENTS_TOP_EVENT: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Statics & Events > Top Event`,
    SQL_QUERY_ERROR_TOP_SQL: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/SQL Query Error > Top SQL Order By`,
    TREND_SUMMARY: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Trend Summary`,
    ALERT_COUNT: `${MAIN_FRAMES.MYSQL_SINGLE_VIEW}/Alert Count`,
  },
  MYSQL_MULTI_VIEW: {
    OVERVIEW: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Overview`,
    SLOW_QUERY: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Slow Query`,
    DB_SESSION_TYPE: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/DB Session Type`,
    RANK_GLOBAL_TEMP_USAGE: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Global Temp Usage`,
    RANK_REDO_USAGE: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Redo Usage`,
    RANK_SORT_MERGE_PASSES: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Sort Merge Passes`,
    RANK_SESSION_TEMP_USAGE: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Session Temp Usage`,
    RANK_QUERIES: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Queries[Delta]`,
    RANK_ALERT: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Alert`,
    RANK_METRIC: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Rank > Metric`,
    ALERT_COUNT: `${MAIN_FRAMES.MYSQL_MULTI_VIEW}/Alert Count`,
  },
  ORACLE_MULTI_VIEW: {
    OVERVIEW: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/Overview`,
    REDO: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/Redo`,
    UNDO_TEMP: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/Undo & Temp`,
    DB_SESSION_TYPE: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Session Type`,
    DB_WAIT_CLASS: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Wait Class`,
    DB_WAIT_CLASS_TREND: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Wait Class Trend`,
    DB_TIME_MODEL: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Time Model`,
    DB_TIME_MODEL_TREND: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/DB Time Model Trend`,
    ALERT_COUNT: `${MAIN_FRAMES.ORACLE_MULTI_VIEW}/Alert Count`,
  },
  PLAN_DIFF_WINDOW: {
    PLAN_DIFF: `${MAIN_FRAMES.PLAN_DIFF_WINDOW}/Plan Diff`,
    PLAN_HASH_VALUE: `${MAIN_FRAMES.PLAN_DIFF_WINDOW}/Plan Hash Value`,
  },
  POSTGRESQL_SINGLE_VIEW: {
    OVERVIEW: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview`,
    OVERVIEW_LOCK_TREE: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Lock Tree`,
    OVERVIEW_ALERT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Alert`,
    OVERVIEW_VACUUM: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Vacuum`,
    OVERVIEW_REPLICATION: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Replication`,
    OVERVIEW_CHECKPOINT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Overview > Checkpoint`,
    SLOW_QUERY: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Slow Query`,
    ADMIN_REFERENCE_ALERT_LOGS: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Admin Reference > Alert Logs`,
    ADMIN_REFERENCE_VACUUM: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Admin Reference > Vacuum`,
    ADMIN_REFERENCE_ADDITIONAL_INFO: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Admin Reference > Additional Information`,
    OBJECT_TOP_OBJECT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Object > Top Object`,
    OBJECT_OBJECT_SCAN: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Object > Object Scan`,
    STATICS_EVENTS_TOP_DIFF_STATISTICS: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Statics & Events > Top Diff Statistics`,
    STATICS_EVENTS_TOP_EVENT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Statics & Events > Top Event`,
    SQL_FUNCTION_TOP_SQL: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/SQL & Function > Top SQL`,
    SQL_FUNCTION_TOP_FUNCTION: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/SQL & Function > Top Function`,
    TREND_SUMMARY: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Trend Summary`,
    ALERT_COUNT: `${MAIN_FRAMES.POSTGRESQL_SINGLE_VIEW}/Alert Count`,
  },
  POSTGRESQL_MULTI_VIEW: {
    OVERVIEW: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Overview`,
    DB_SESSION_TYPE: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/DB Session Type`,
    RANK: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank`,
    RANK_QUERY_IO: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Query IO`,
    RANK_TRANSACTION_TIME: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Transaction Time`,
    RANK_VACUUM_USAGE: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Vacuum Usage`,
    RANK_TEMP_USAGE: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Temp Usage`,
    RANK_FILESYSTEM_USAGE: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Filesystem Usage`,
    RANK_ALERT: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Alert`,
    RANK_TOP_METRIC_1MIN: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Top Metric 1 minute`,
    RANK_TOP_METRIC_10MIN: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Rank > Top Metric 1 minute`,
    SLOW_QUERY: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Slow Query`,
    ALERT_COUNT: `${MAIN_FRAMES.POSTGRESQL_MULTI_VIEW}/Alert Count`,
  },
  SQLSERVER_SINGLE_VIEW: {
    ALERT_LOGS: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Alert Logs`,
    DB_METRIC: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/DB Metric`,
    TREND_24: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/24 Trend`,
    TEMPDB_USAGE_GAUGE: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/TempDB Usage > Gauge`,
    TEMPDB_USAGE_TREND: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/TempDB Usage > Trend`,
    OVERVIEW_CPU_SQL_ACTIVE_SESSION_LOCK: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Overview > CPU & SQL, Active Session Lock`,
    OVERVIEW_ACTIVE_SESSION_ELAPSED: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Overview > Active Session Elapsed Time Scatter`,
    OVERVIEW_SQL_ELAPSED: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Overview > SQL Elapsed Time Scatter`,
    SESSION_ELAPSED: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Session Elapsed Time`,
    ALERT_COUNT: `${MAIN_FRAMES.SQLSERVER_SINGLE_VIEW}/Alert Count`,
  },
  SESSION_TAB: {
    ACTIVE_BACKENDS: `${MAIN_FRAMES.SESSION_TAB}/Active Backends`,
    BLOCKED: `${MAIN_FRAMES.SESSION_TAB}/Blocked`,
    BLOCKED_DETAIL: `${MAIN_FRAMES.SESSION_TAB}/Blocked Detail`,
    WAIT_CHAINS: `${MAIN_FRAMES.SESSION_TAB}/Wait Chains`,
    WAIT_CLASS: `${MAIN_FRAMES.SESSION_TAB}/Wait Class`,
    WAIT_EVENT: `${MAIN_FRAMES.SESSION_TAB}/Wait Event`,
    LOCK_TREE: `${MAIN_FRAMES.SESSION_TAB}/Lock Tree`,
    META_LOCK_TREE: `${MAIN_FRAMES.SESSION_TAB}/Meta Lock Tree`,
    SQL_SUMMARY: `${MAIN_FRAMES.SESSION_TAB}/SQL Summary`,
    SESSION: `${MAIN_FRAMES.SESSION_TAB}/Session`,
    SESSIONS: `${MAIN_FRAMES.SESSION_TAB}/Sessions`,
    THREAD: `${MAIN_FRAMES.SESSION_TAB}/Thread`,
  },
  SESSION_DETAIL_WINDOW: {
    FULL_TEXT: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Full Text`,
    KILL_SESSION: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Kill Session`,
    INFORMATION: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Information`,
    PLAN: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Plan`,
    EVENT_INFO: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Event Info`,
    PARAMETER_LIST: `${MAIN_FRAMES.SESSION_DETAIL_WINDOW}/Parameter List`,
  },
  SESSION_MANAGER_WINDOW: {
    SESSION: `${MAIN_FRAMES.SESSION_MANAGER_WINDOW}/Session`,
    BLOCKED: `${MAIN_FRAMES.SESSION_MANAGER_WINDOW}/Blocked`,
  },
  SQL_ELAPSED_LIST_WINDOW: {
    SLOW_QUERY: `${MAIN_FRAMES.SQL_ELAPSED_LIST_WINDOW}/Slow Query`,
  },
  SQL_DETAIL_WINDOW: {
    INFORMATION: `${MAIN_FRAMES.SQL_DETAIL_WINDOW}/Information`,
    PLAN: `${MAIN_FRAMES.SQL_DETAIL_WINDOW}/Plan`,
  },
  FUNCTION_DETAIL_WINDOW: {
    INFORMATION: `${MAIN_FRAMES.FUNCTION_DETAIL_WINDOW}/Information`,
    SRC_VIEW: `${MAIN_FRAMES.FUNCTION_DETAIL_WINDOW}/Source View`,
  },
  USER_ENVIRONMENT: {
    STAT_INFO: `${MAIN_FRAMES.USER_ENVIRONMENT}/Stat Info`,
    SELECTED_INSTANCE_ID: `${MAIN_FRAMES.USER_ENVIRONMENT}/Selected Instance ID`,
    SLOW_QUERY_OPTION: `${MAIN_FRAMES.USER_ENVIRONMENT}/Slow Query Option`,
    SAVE: `${MAIN_FRAMES.USER_ENVIRONMENT}/[Save]`,
  },
  PARAMETER_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.PARAMETER_DETAIL}/Information`,
  },
} as const;
