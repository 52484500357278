/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  PromiseAxiosResponse,
  timeout5SecAxiosInstance,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  ApiResponseFilterItem,
  UrlMonitoringListResponse,
  UrlResponseStatusResponse,
  UrMonitoringMetricResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
  /* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getUrlDetailUrlMonitoringControllerAxios = ({
  applicationId,
  filterLogicalOperator,
  filterTokens0Key,
  filterTokens0Operator,
  filterTokens0Value,
  fromTime,
  interval,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationId?: string;
  filterLogicalOperator?: string;
  filterTokens0Key?: string;
  filterTokens0Operator?: string;
  filterTokens0Value?: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UrMonitoringMetricResponse> => {
  const localVarPath = '/api/v8/application/url/detail';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationId,
      filterLogicalOperator,
      filterTokens0Key,
      filterTokens0Operator,
      filterTokens0Value,
      fromTime,
      interval,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getUrlDetailListUrlMonitoringControllerAxios = ({
  applicationId,
  filterLogicalOperator,
  filterTokens0Key,
  filterTokens0Operator,
  filterTokens0Value,
  fromTime,
  interval,
  period,
  statusFilter,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationId?: string;
  filterLogicalOperator?: string;
  filterTokens0Key?: string;
  filterTokens0Operator?: string;
  filterTokens0Value?: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  statusFilter?: Array<string>;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UrlMonitoringListResponse> => {
  const localVarPath = '/api/v8/application/url/detail/list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationId,
      filterLogicalOperator,
      filterTokens0Key,
      filterTokens0Operator,
      filterTokens0Value,
      fromTime,
      interval,
      period,
      statusFilter,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getFiltersUrlMonitoringControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseFilterItem> => {
  const localVarPath = '/api/v8/application/url/filter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getUrlListUrlMonitoringControllerAxios = ({
  applicationGroupIds,
  filterLogicalOperator,
  filterTokens0Key,
  filterTokens0Operator,
  filterTokens0Value,
  fromTime,
  interval,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationGroupIds?: Array<string>;
  filterLogicalOperator?: string;
  filterTokens0Key?: string;
  filterTokens0Operator?: string;
  filterTokens0Value?: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UrMonitoringMetricResponse> => {
  const localVarPath = '/api/v8/application/url/list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationGroupIds,
      filterLogicalOperator,
      filterTokens0Key,
      filterTokens0Operator,
      filterTokens0Value,
      fromTime,
      interval,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getUrlStatusUrlMonitoringControllerAxios = ({
  applicationGroupIds,
  fromTime,
  interval,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  applicationGroupIds?: Array<string>;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UrlResponseStatusResponse> => {
  const localVarPath = '/api/v8/application/url/status';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      applicationGroupIds,
      fromTime,
      interval,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getUrlDetailUrlMonitoringControllerAxios,
  getUrlDetailListUrlMonitoringControllerAxios,
  getFiltersUrlMonitoringControllerAxios,
  getUrlListUrlMonitoringControllerAxios,
  getUrlStatusUrlMonitoringControllerAxios,
};
