/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  FilterResponse,
  NotificationFilter,
  NotificationHistoryResponse,
  NotificationStatusResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getFilterNotificationHistoryControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FilterResponse> => {
  const localVarPath = '/api/v6/notification/filters';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getStatusDetailNotificationHistoryControllerAxios = ({
  methodType,
  resultId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  methodType: 'email' | 'onSite' | 'slack' | 'sms' | 'telegram' | 'webhook';
  resultId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<NotificationStatusResponse> => {
  const localVarPath = '/api/v6/notification/status/{methodType}/{resultId}'
    .replace(`{${'methodType'}}`, encodeURIComponent(String(methodType)))
    .replace(`{${'resultId'}}`, encodeURIComponent(String(resultId)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getNotificationHistoryControllerAxios = ({
  fromTime,
  isReport,
  period,
  timezone,
  toTime,
  notificationFilter,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  isReport?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  timezone?: string;
  toTime?: string;
  notificationFilter?: NotificationFilter;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<NotificationHistoryResponse> => {
  const localVarPath = '/api/v6/notification/history-list';
  const httpMethod = 'post';

  const data: any = notificationFilter;
  const config: any = {
    params: {
      fromTime,
      isReport,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getFilterNotificationHistoryControllerAxios,
  getStatusDetailNotificationHistoryControllerAxios,
  getNotificationHistoryControllerAxios,
};
