<script setup lang="ts">
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import { useSimpleTextTooltip } from '@/common/components/molecules/simpleTextTooltip/simpleTextTooltip.uses';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { clickoutside as vClickOutside } from '@/common/directives/clickoutside';
import { debounce } from 'lodash-es';
import { IconType } from '@/types/icon.types';

interface Props {
  displayEventType?: 'click' | 'hover';
  pos?: 'top' | 'bottom' | 'left' | 'right';
  teleportTo?: string;
  iconType?: 'info' | 'question' | string; // 2가지 형태 or icon 값 주입
  customTooltipClass?: string;
  nonePadding?: boolean;
  preLine?: boolean;
}

interface Slots {
  default?: () => any;
}

const props = withDefaults(defineProps<Props>(), {
  displayEventType: 'click',
  pos: 'bottom',
  teleportTo: '#app',
  iconType: 'question',
  customTooltipClass: 'help-tooltip__tooltip',
});
const slots = defineSlots<Slots>();

const ICON_NAME = {
  info: 'icon-fill-error-circle',
  question: 'icon-help-circle',
};

const mouseEvt = ref<MouseEvent | null>(null);
const { onMouseEventInTarget } = useSimpleTextTooltip(mouseEvt);

const iconName = computed<IconType>(() => ICON_NAME?.[props.iconType] ?? props.iconType);

const toggleTooltip = debounce((e: MouseEvent | null) => {
  if (mouseEvt.value) {
    mouseEvt.value = null;
  } else {
    mouseEvt.value = e;
  }
}, 150);
const onClickTooltip = (e: MouseEvent) => {
  toggleTooltip(e);
};
const onClickOutsideTooltip = async () => {
  if (props.displayEventType === 'click' && mouseEvt.value) {
    toggleTooltip(null);
  }
};

const wheelEventHandler = () => {
  if (mouseEvt.value) {
    toggleTooltip(null);
  }
};

const clickEventHandler = (e) => {
  const target = e.target as HTMLElement;
  const cursorStyle = window.getComputedStyle(target).cursor;
  if (cursorStyle === 'pointer') {
    toggleTooltip(null);
  }
};

onMounted(() => {
  window.addEventListener('wheel', wheelEventHandler);
  window.addEventListener('click', clickEventHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener('wheel', wheelEventHandler);
  window.removeEventListener('click', clickEventHandler);
});
</script>

<template>
  <div
    :class="[
      'help-tooltip',
      {
        'none-padding': props.nonePadding,
      },
    ]"
  >
    <ev-button
      v-if="props.displayEventType === 'click'"
      type="icon"
      size="small"
      @click.stop="onClickTooltip"
    >
      <ev-icon
        :icon="iconName"
        size="small"
        class="help-icon"
      />
    </ev-button>
    <ev-icon
      v-else
      :icon="iconName"
      size="small"
      class="help-icon"
      @mouseenter="onMouseEventInTarget"
      @mouseleave="onMouseEventInTarget"
    />

    <simple-text-tooltip
      :mouse-event="mouseEvt"
      :show-tail="false"
      :base-pos="props.pos"
      :teleport-to="props.teleportTo"
      :custom-class="props.customTooltipClass"
      :none-padding="!!props?.nonePadding"
      :pre-line="props.preLine"
    >
      <div
        v-click-outside="onClickOutsideTooltip"
        class="help-tooltip__tooltip-content"
      >
        <template v-if="slots.default">
          <slot />
        </template>
        <template v-else> No Tooltip Text </template>
      </div>
    </simple-text-tooltip>
  </div>
</template>

<style lang="scss">
.help-tooltip {
  display: flex;
  align-items: center;

  &__tooltip-content * {
    pointer-events: none;
  }
  .help-icon {
    cursor: pointer;
  }
  .icon-help-circle.help-icon {
    background-color: var(--icon-base-color) !important;
  }
}
.help-tooltip__tooltip {
  background-color: var(--help-tooltip-bg-color) !important;
  color: var(--help-tooltip-font-color);
}
.help-tooltip__container {
  padding: 0;
}
</style>
