export const ROLE_PERMISSION_KEY = {
  DASHBOARD: {
    DASHBOARD_MANAGEMENT: 'dashboard_management',
    DASHBOARD: 'dashboard',
    DASHBOARD_MONITORING: 'dashboard_monitoring',
    DASHBOARD_MONITORING_ALERT_VIEW: 'dashboard_monitoring_alert_view',
    DASHBOARD_MONITORING_ALERT_VIEW_CLEAR: 'dashboard_monitoring_alert_view_clear',
    DASHBOARD_GROUP: 'dashboard_group',
    DASHBOARD_GROUP_EDIT: 'dashboard_group_edit', // add, edit, delete 2024.05.30 통합
    DASHBOARD_LIST: 'dashboard_list',
    DASHBOARD_LIST_EDIT: 'dashboard_list_edit', // add, edit, delete 2024.05.30 통합
    DASHBOARD_LIST_MOVE: 'dashboard_list_move',
    DASHBOARD_LIST_EXPORT: 'dashboard_list_export',
    DASHBOARD_LIST_SHARE: 'dashboard_list_share',
    DASHBOARD_LIST_SET_REPORT: 'dashboard_list_set_report',
    DASHBOARD_LIST_SETUP_DASHBOARD_BY_MENU: 'dashboard_list_setup_dashboard_by_menu',
    DASHBOARD_LIST_SETUP_ANALYSIS_BOARD_BY_MENU: 'dashboard_list_setup_analysis_board_by_menu',
    DASHBOARD_LIST_IMPORT: 'dashboard_list_import',
    DASHBOARD_LIST_IMPORT_VIEWPACK: 'dashboard_list_import_viewpack',
    DASHBOARD_FEATURED_DASHBOARD: 'dashboard_featured_dashboard',
    DASHBOARD_FEATURED_DASHBOARD_SET_AS_START_SCREEN:
      'dashboard_featured_dashboard_set_as_start_screen',
    DASHBOARD_FEATURED_DASHBOARD_EDIT: 'dashboard_featured_dashboard_edit',
    DASHBOARD_FEATURED_DASHBOARD_SETUP_DASHBOARD_BY_MENU:
      'dashboard_featured_dashboard_setup_dashboard_by_menu',
    DASHBOARD_FEATURED_ANALYSIS_BOARD_SETUP_DASHBOARD_BY_MENU:
      'dashboard_featured_analysis_board_setup_dashboard_by_menu',
    DASHBOARD_FEATURED_DASHBOARD_EXPORT: 'dashboard_featured_dashboard_export',
    DASHBOARD_FEATURED_REPORT: 'dashboard_featured_report',
    DASHBOARD_FEATURED_REPORT_SET_AS_START_SCREEN: 'dashboard_featured_report_set_as_start_screen',
    DASHBOARD_FEATURED_REPORT_EDIT: 'dashboard_featured_report_edit',
    DASHBOARD_FEATURED_REPORT_SETUP_DASHBOARD_BY_MENU:
      'dashboard_featured_report_setup_dashboard_by_menu',
    DASHBOARD_FEATURED_REPORT_SETUP_ANALYSIS_BOARD_BY_MENU:
      'dashboard_featured_report_setup_analysis_board_by_menu',
    DASHBOARD_FEATURED_REPORT_EXPORT: 'dashboard_featured_report_export',
    DASHBOARD_EDIT_VIEWPACK: 'dashboard_edit_viewpack', // add, edit, delete 2024.05.30 통합
    DASHBOARD_EDIT_VIEWPACK_EDIT: 'dashboard_edit_viewpack_edit',
    DASHBOARD_EDIT_VIEWPACK_EXPORT: 'dashboard_edit_viewpack_export',
  },
  MONITORING: {
    MONITORING_MANAGEMENT: 'monitoring_management',
    MONITORING_INFRASTRUCTURE: 'monitoring_infrastructure',
    MONITORING_INFRASTRUCTURE_DASHBOARD: 'monitoring_infrastructure_dashboard',
    MONITORING_INFRASTRUCTURE_HOST: 'monitoring_infrastructure_host',
    MONITORING_INFRASTRUCTURE_CONTAINER: 'monitoring_infrastructure_container',
    MONITORING_INFRASTRUCTURE_NETWORK_DEVICE: 'monitoring_infrastructure_network_device',
    MONITORING_INFRASTRUCTURE_TOOL: 'monitoring_infrastructure_tool',
    MONITORING_INFRASTRUCTURE_TOOL_SCRIPT_MANAGER: 'monitoring_infrastructure_tool_script_manager',
    MONITORING_INFRASTRUCTURE_TOOL_SCRIPT_MANAGER_HISTORY:
      'monitoring_infrastructure_tool_script_manager_history',
    MONITORING_INFRASTRUCTURE_NETWORK_PERFORMANCE_MONITORING:
      'monitoring_infrastructure_network_performance_monitoring',
    MONITORING_KAFKA: 'monitoring_kafka',
    MONITORING_KAFKA_DASHBOARD: 'monitoring_kafka_dashboard',
    MONITORING_KUBERNETES: 'monitoring_kubernetes',
    MONITORING_KUBERNETES_DASHBOARD: 'monitoring_kubernetes_dashboard',
    MONITORING_KUBERNETES_CLUSTER_TOPOLOGY: 'monitoring_kubernetes_cluster_topology',
    MONITORING_KUBERNETES_OBJECT_TOPOLOGY: 'monitoring_kubernetes_object_topology',
    MONITORING_KUBERNETES_CLUSTER: 'monitoring_kubernetes_cluster',
    MONITORING_KUBERNETES_POD: 'monitoring_kubernetes_pod',
    MONITORING_KUBERNETES_POD_KILL: 'monitoring_kubernetes_pod_kill',
    MONITORING_KUBERNETES_POD_SHELL: 'monitoring_kubernetes_pod_shell',
    MONITORING_KUBERNETES_NAMESPACE: 'monitoring_kubernetes_namespace',
    MONITORING_KUBERNETES_NODE: 'monitoring_kubernetes_node',
    MONITORING_KUBERNETES_WORKLOAD: 'monitoring_kubernetes_workload',
    MONITORING_KUBERNETES_NETWORK: 'monitoring_kubernetes_network',
    MONITORING_KUBERNETES_CONFIG_STORAGE: 'monitoring_kubernetes_config_storage',
    MONITORING_KUBERNETES_ACCESS_CONTROL: 'monitoring_kubernetes_access_control',
    MONITORING_CLOUD: 'monitoring_cloud',
    MONITORING_CLOUD_DASHBOARD: 'monitoring_cloud_dashboard',
    MONITORING_CLOUD_AWS: 'monitoring_cloud_aws',
    MONITORING_CLOUD_AWS_EC2: 'monitoring_cloud_aws_ec2',
    MONITORING_CLOUD_AWS_LAMBDA: 'monitoring_cloud_aws_lambda',
    MONITORING_CLOUD_AWS_S3: 'monitoring_cloud_aws_s3',
    MONITORING_CLOUD_AWS_DOCUMENT_DB: 'monitoring_cloud_aws_document_db',
    MONITORING_CLOUD_AWS_DYNAMO_DB: 'monitoring_cloud_aws_dynamo_db',
    MONITORING_CLOUD_AWS_ELASTI_CACHE: 'monitoring_cloud_aws_elasti_cache',
    MONITORING_CLOUD_AWS_RDS: 'monitoring_cloud_aws_rds',
    MONITORING_CLOUD_AWS_REDSHIFT: 'monitoring_cloud_aws_red_shift',
    MONITORING_CLOUD_AWS_COST_EXPLORER: 'monitoring_cloud_aws_explorer_cost',
    MONITORING_APPLICATION: 'monitoring_application',
    MONITORING_APPLICATION_DASHBOARD: 'monitoring_application_dashboard',
    MONITORING_APPLICATION_WAS: 'monitoring_application_was',
    MONITORING_APPLICATION_WAS_HEAP_DUMP: 'monitoring_application_was_heap_dump',
    MONITORING_APPLICATION_TRANSACTION: 'monitoring_application_transaction',
    MONITORING_APPLICATION_URL_MONITORING: 'monitoring_application_url_monitoring',
    MONITORING_APPLICATION_REAL_USER_MONITORING: 'monitoring_application_real_user_monitoring',
    MONITORING_APPLICATION_PAGE_PERFORMANCE_MONITORING:
      'monitoring_application_page_performance_monitoring',
    MONITORING_APPLICATION_PAGE_PERFORMANCE_ANALYSIS:
      'monitoring_application_page_performance_analysis',
    MONITORING_APPLICATION_BROWSER_ERROR_TRACKING: 'monitoring_application_browser_error_tracking',
    MONITORING_DATABASE: 'monitoring_database',
    MONITORING_DATABASE_DASHBOARD: 'monitoring_database_dashboard',
    MONITORING_DATABASE_INSTANCE: 'monitoring_database_instance',
    MONITORING_DATABASE_INSTANCE_SESSION_KILL: 'monitoring_database_instance_session_kill',
    // <-- Maxgauge Mode
    MONITORING_ORACLE: 'monitoring_oracle',
    MONITORING_ORACLE_DASHBOARD: 'monitoring_oracle_dashboard',
    MONITORING_ORACLE_SQL_ANALYSIS: 'monitoring_oracle_sql_analysis',
    MONITORING_ORACLE_EVENT_ANALYSIS: 'monitoring_oracle_event_analysis',
    MONITORING_ORACLE_SESSION_ANALYSIS: 'monitoring_oracle_session_analysis',
    MONITORING_ORACLE_PERFORMANCE_ANALYSIS: 'monitoring_oracle_performance_analysis',
    MONITORING_ORACLE_DATASPACE_ANALYSIS: 'monitoring_oracle_dataspace_analysis',
    MONITORING_POSTGRESQL: 'monitoring_postgresql',
    MONITORING_POSTGRESQL_DASHBOARD: 'monitoring_postgresql_dashboard',
    MONITORING_POSTGRESQL_SQL_ANALYSIS: 'monitoring_postgresql_sql_analysis',
    MONITORING_POSTGRESQL_SESSION_ANALYSIS: 'monitoring_postgresql_session_analysis',
    MONITORING_POSTGRESQL_PERFORMANCE_ANALYSIS: 'monitoring_postgresql_performance_analysis',
    MONITORING_POSTGRESQL_DATASPACE_ANALYSIS: 'monitoring_postgresql_dataspace_analysis',
    MONITORING_MYSQL: 'monitoring_mysql',
    MONITORING_MYSQL_DASHBOARD: 'monitoring_mysql_dashboard',
    MONITORING_MYSQL_SQL_ANALYSIS: 'monitoring_mysql_sql_analysis',
    MONITORING_MYSQL_SESSION_ANALYSIS: 'monitoring_mysql_session_analysis',
    MONITORING_MYSQL_PERFORMANCE_ANALYSIS: 'monitoring_mysql_performance_analysis',
    MONITORING_MYSQL_DATASPACE_ANALYSIS: 'monitoring_mysql_dataspace_analysis',
    MONITORING_SQLSERVER: 'monitoring_sqlserver',
    MONITORING_SQLSERVER_DASHBOARD: 'monitoring_sqlserver_dashboard',
    MONITORING_SQLSERVER_SQL_ANALYSIS: 'monitoring_sqlserver_sql_analysis',
    MONITORING_SQLSERVER_SESSION_ANALYSIS: 'monitoring_sqlserver_session_analysis',
    MONITORING_SQLSERVER_PERFORMANCE_ANALYSIS: 'monitoring_sqlserver_performance_analysis',
    MONITORING_SQLSERVER_DATASPACE_ANALYSIS: 'monitoring_sqlserver_dataspace_analysis',
    MONITORING_REDIS: 'monitoring_redis',
    MONITORING_REDIS_DASHBOARD: 'monitoring_redis_dashboard',
    MONITORING_CUBRID: 'monitoring_cubrid',
    MONITORING_CUBRID_DASHBOARD: 'monitoring_cubrid_dashboard',
    MONITORING_MONGO: 'monitoring_mongo',
    MONITORING_MONGO_DASHBOARD: 'monitoring_mongo_dashboard',
    MONITORING_TIBERO: 'monitoring_tibero', // NOTE: 티베로 임시
    MONITORING_TIBERO_DASHBOARD: 'monitoring_tibero_dashboard', // NOTE: 티베로 임시
    MONITORING_ALTIBASE: 'monitoring_altibase', // NOTE: 알티베이스 임시
    MONITORING_ALTIBASE_DASHBOARD: 'monitoring_altibase_dashboard', // NOTE: 알티베이스 임시
    // Maxgauge Mode -->
    MONITORING_BUSINESS: 'monitoring_business',
    MONITORING_BUSINESS_DASHBOARD: 'monitoring_business_dashboard',
    MONITORING_BUSINESS_LIST: 'monitoring_business_list',
    MONITORING_BUSINESS_MAP: 'monitoring_business_map',
    MONITORING_BUSINESS_MAP_FAULT_CONTROL: 'monitoring_business_map_fault_control',
    MONITORING_LOGS: 'monitoring_logs',
    MONITORING_LOGS_DASHBOARD: 'monitoring_logs_dashboard',
    MONITORING_LOGS_LOGGING: 'monitoring_logs_logging',
    MONITORING_LOGS_LIVE_TAIL: 'monitoring_logs_live_tail',
    MONITORING_ALERT: 'monitoring_alert',
    MONITORING_ALERT_DASHBOARD: 'monitoring_alert_dashboard',
    MONITORING_ALERT_LIST: 'monitoring_alert_list',
    MONITORING_ALERT_LIST_CLEAR: 'monitoring_alert_list_clear',
    MONITORING_ALERT_NOTIFICATION_HISTORY: 'monitoring_alert_notification_history',
    MONITORING_ANALYSIS: 'monitoring_analysis',
    MONITORING_ANALYSIS_TOOL: 'monitoring_analysis_tool',
    MONITORING_ANALYSIS_APPLICATION: 'monitoring_analysis_application',
    MONITORING_ANALYSIS_DATABASE: 'monitoring_analysis_database',
    MONITORING_REPORT: 'monitoring_report',
    MONITORING_REPORT_ALERT_HISTORY: 'monitoring_report_alert_history',
    MONITORING_DASHBOARD_REPORT_EXPORT: 'monitoring_dashboard_report_export',
    MONITORING_BUSINESS_REPORT_EXPORT: 'monitoring_business_report_export',
  },
  SETTING: {
    SETTING_MANAGEMENT: 'setting_management',
    SETTING_ACCOUNT: 'setting_account',
    SETTING_ACCOUNT_USER_USER_GROUP: 'setting_account_user_user_group',
    SETTING_ACCOUNT_USER_MANAGEMENT: 'setting_account_user_management',
    SETTING_PLATFORM: 'setting_platform',
    SETTING_PLATFORM_INFRASTRUCTURE: 'setting_platform_infrastructure',
    SETTING_PLATFORM_INFRASTRUCTURE_HOST: 'setting_platform_infrastructure_host',
    SETTING_PLATFORM_INFRASTRUCTURE_NETWORK_PERFORMANCE_MONITORING:
      'setting_platform_infrastructure_network_performance_monitoring',
    SETTING_PLATFORM_INFRASTRUCTURE_NETWORK_DEVICE:
      'setting_platform_infrastructure_network_device',
    SETTING_PLATFORM_INFRASTRUCTURE_OID_PACK_MANAGER:
      'setting_platform_infrastructure_oid_pack_manager',
    SETTING_PLATFORM_INFRASTRUCTURE_MESSAGE_QUEUE: 'setting_platform_infrastructure_message_queue',
    SETTING_PLATFORM_CLOUD: 'setting_platform_cloud',
    SETTING_PLATFORM_CLOUD_RESOURCE: 'setting_platform_cloud_resource',
    SETTING_PLATFORM_CLOUD_ACCOUNT: 'setting_platform_cloud_account',
    SETTING_PLATFORM_CLOUD_COLLECTOR: 'setting_platform_cloud_collector',

    SETTING_PLATFORM_KUBERNETES: 'setting_platform_kubernetes',
    SETTING_PLATFORM_KUBERNETES_CLUSTER: 'setting_platform_kubernetes_cluster',
    SETTING_PLATFORM_APPLICATION: 'setting_platform_application',
    SETTING_PLATFORM_APPLICATION_WAS: 'setting_platform_application_was',
    SETTING_PLATFORM_APPLICATION_URL_MONITORING: 'setting_platform_application_url_monitoring',
    SETTING_PLATFORM_APPLICATION_REAL_USER_MONITORING:
      'setting_platform_application_real_user_monitoring',
    SETTING_PLATFORM_DATABASE: 'setting_platform_database',
    SETTING_PLATFORM_EXTERNAL_RESOURCE: 'setting_platform_external_resource',
    SETTING_PLATFORM_DATABASE_INSTANCE: 'setting_platform_database_instance',
    SETTING_MONITORING: 'setting_monitoring',
    SETTING_MONITORING_BUSINESS: 'setting_monitoring_business',
    SETTING_MONITORING_LOG: 'setting_monitoring_log',
    SETTING_MONITORING_LOG_LOGGING: 'setting_monitoring_log_logging',
    SETTING_MONITORING_LOG_PATH: 'setting_monitoring_log_path',
    SETTING_MONITORING_REPORT: 'setting_monitoring_report',
    SETTING_MONITORING_REPORT_DASHBOARD_EXPORT: 'setting_monitoring_report_dashboard_export',
    SETTING_MONITORING_REPORT_BUSINESS_EXPORT: 'setting_business_report_dashboard_export',
    SETTING_MONITORING_REPORT_SCHEDULE: 'setting_monitoring_report_schedule',
    SETTING_ALERT: 'setting_alert',
    SETTING_ALERT_USER_ALERT: 'setting_alert_user_alert',
    SETTING_ALERT_SYSTEM_ALERT: 'setting_alert_system_alert',
    SETTING_ALERT_NOTIFICATION: 'setting_alert_notification',
    SETTING_MANAGEMENT_MANAGEMENT: 'setting_management_management',
    SETTING_MANAGEMENT_AGENT: 'setting_management_agent', // NOTE: 무조건 마스터만 접근가능하여 별도의 키가 없지만 키 없으면 접근이 제한됨
    SETTING_MANAGEMENT_AGENT_EXECUTION: 'setting_management_execution', // NOTE: 무조건 마스터만 접근가능하여 별도의 키가 없지만 키 없으면 접근이 제한됨
    SETTING_MANAGEMENT_DATA_RETENTION: 'setting_management_data_retention',
    SETTING_MANAGEMENT_TAG: 'setting_management_tag',
    SETTING_MANAGEMENT_LICENSE: 'setting_management_license',
    SETTING_MANAGEMENT_NOTICE: 'setting_management_notice',
    SETTING_MANAGEMENT_LICENSE_OVERVIEW: 'setting_management_license_overview',
  },
};

export const PERMISSION_LIST = {
  DASHBOARD: 'dashboard',
  MONITORING: 'monitoring',
  SETTING: 'setting',
} as const;
