/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  AdditionalInfoV7Response,
  AdminRefVacuumV7Response,
  CheckPointV7Response,
  FunctionDetailV7Response,
  FunctionSrcV7Response,
  LastParameterResponse,
  LongActiveBackendV7Response,
  MultiInstanceRequest,
  MultiOverviewV7Response,
  MultiSessionKillResponse,
  MultiSessionTargetForm,
  ObjectDetailTrendV7Response,
  ObjectInfoV7Response,
  ObjectScanRatioV7Response,
  ObjectTrendV7Response,
  OverViewV7Response,
  ParameterDetailResponse,
  ParameterResponse,
  ReplicationInfoV7Response,
  SessionDetailV7Response,
  SessionSearchListV7Response,
  SlowQueryListV7Response,
  SqlPlanRequest,
  SqlPlanV7Response,
  TopDiffStatisticsV7Response,
  TopFunctionV7Response,
  TopObjectAnalyzeTimeV7Response,
  TopObjectListAgeV7Response,
  TopObjectListDeadTupleV7Response,
  TopObjectListScanCountV7Response,
  TopObjectListTableSpaceV7Response,
  TopObjectScanListV7Response,
  TopObjectTableScanRatioV7Response,
  TopObjectV7Response,
  TopSessionEventV7Response,
  TopSessionSqlV7Response,
  TopUserV7Response,
  TrendTopObjectListV7Response,
  VacuumV7Response,
  VacuumingBackendV7Response,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getAdditionalInfoPostgresqlV7ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AdditionalInfoV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/additional-info'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getAdminReferencePostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AdminRefVacuumV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/admin-reference'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getCheckpointPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<CheckPointV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/checkpoint'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getFunctionDetailPostgresqlV7ControllerAxios = ({
  funcName,
  instanceId,
  schemaName,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  funcName: string;
  instanceId: string;
  schemaName: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FunctionDetailV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/functions'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      funcName,
      period,
      schemaName,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getFunctionSourcePostgresqlV7ControllerAxios = ({
  funcName,
  instanceId,
  schemaName,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  funcName: string;
  instanceId: string;
  schemaName: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FunctionSrcV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/functions/funcsrc'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      funcName,
      schemaName,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getObjectDetailPostgresqlV7ControllerAxios = ({
  instanceId,
  objectName,
  fromTime,
  interval,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  objectName: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ObjectDetailTrendV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/object/detail-trend'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      interval,
      objectName,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getObjectSizeInfoPostgresqlV7ControllerAxios = ({
  firstTime,
  instanceId,
  lastTime,
  objectName,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  firstTime: string;
  instanceId: string;
  lastTime: string;
  objectName?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ObjectInfoV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/object/info'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      firstTime,
      lastTime,
      objectName,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getObjectNameListPostgresqlV7ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ObjectTrendV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/object-names'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topObjectIndexScanPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ObjectScanRatioV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/object-ratio'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopObjectPostgresqlV7ControllerAxios = ({
  firstTime,
  instanceId,
  lastTime,
  col,
  dbName,
  objectName,
  size,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  firstTime: string;
  instanceId: string;
  lastTime: string;
  col?: 'diff_all_size' | 'first_all_size' | 'last_all_size';
  dbName?: string;
  objectName?: string;
  size?: number;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopObjectV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/object/top'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      col,
      dbName,
      firstTime,
      lastTime,
      objectName,
      size,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getObjectSizeTrendPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  interval,
  objectName,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  objectName?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ObjectTrendV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/object/trend'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      interval,
      objectName,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopObjectAgePostgresqlV7ControllerAxios = ({
  instanceId,
  size,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopObjectListAgeV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/objects-10min/age'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopObjectAnalyzeTimePostgresqlV7ControllerAxios = ({
  instanceId,
  size,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopObjectAnalyzeTimeV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/objects-10min/analyze-time'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopObjectDeadTuplePostgresqlV7ControllerAxios = ({
  instanceId,
  size,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopObjectListDeadTupleV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/objects-10min/dead-tuple'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopObjectScanCountPostgresqlV7ControllerAxios = ({
  instanceId,
  size,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopObjectListScanCountV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/objects-10min/scan-count'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopObjectTableScanRatioPostgresqlV7ControllerAxios = ({
  instanceId,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopObjectTableScanRatioV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/objects-10min/scan-ratio'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopObjectTableSpacePostgresqlV7ControllerAxios = ({
  instanceId,
  size,
  tableSpace,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  tableSpace: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopObjectListTableSpaceV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/objects-10min/tablespace-size'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      size,
      tableSpace,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const multiInstanceOverviewPostgresqlV7ControllerAxios = ({
  instanceIds,
  metricNames,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  metricNames: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MultiOverviewV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/overview';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      metricNames,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterHistoryPostgresqlV7ControllerAxios = ({
  baseTime,
  compareTime,
  instanceId,
  differentSelectors,
  name,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  baseTime: string;
  compareTime: string;
  instanceId: string;
  differentSelectors?: Array<boolean>;
  name?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ParameterResponse> => {
  const localVarPath = '/api/v7/postgres/pa/parameter/history';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      baseTime,
      compareTime,
      differentSelectors,
      instanceId,
      name,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterHistoryDetailPostgresqlV7ControllerAxios = ({
  instanceId,
  name,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  name: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ParameterDetailResponse> => {
  const localVarPath = '/api/v7/postgres/pa/parameter/history/detail';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      instanceId,
      name,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const overviewPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<OverViewV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/rtm/{instanceId}/overview'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getReplicationPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ReplicationInfoV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/rtm/{instanceId}/replication'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionsPostgresqlV7ControllerAxios = ({
  instanceIds,
  appName,
  clientAddress,
  fromQueryStartTime,
  fromTime,
  isFullText,
  isReport,
  pageNum,
  pageSize,
  period,
  pid,
  sqlText,
  tenantId,
  timezone,
  toQueryStartTime,
  toTime,
  userName,
  waitEvent,
  waitEventType,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  appName?: string;
  clientAddress?: string;
  fromQueryStartTime?: string;
  fromTime?: string;
  isFullText?: boolean;
  isReport?: boolean;
  pageNum?: number;
  pageSize?: number;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  pid?: number;
  sqlText?: string;
  tenantId?: string;
  timezone?: string;
  toQueryStartTime?: string;
  toTime?: string;
  userName?: string;
  waitEvent?: string;
  waitEventType?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionSearchListV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/sessions';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      appName,
      clientAddress,
      fromQueryStartTime,
      fromTime,
      instanceIds,
      isFullText,
      isReport,
      pageNum,
      pageSize,
      period,
      pid,
      sqlText,
      tenantId,
      timezone,
      toQueryStartTime,
      toTime,
      userName,
      waitEvent,
      waitEventType,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSlowqueryPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SlowQueryListV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/slowquery'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      isFullText,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopDiffStatisticsPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopDiffStatisticsV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/top-diff-statistics'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopFunctionPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  sort,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  sort?: 'calls' | 'selfTime' | 'totalTime';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopFunctionV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/top-function-call'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      sort,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopScanObjectV2PostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopObjectScanListV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/top-object-scan'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopWaitEventPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSessionEventV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/top-session-event'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopSessionSqlPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  size,
  sort,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  sort?: 'calls' | 'localBlksRead' | 'sharedBlksRead' | 'tempBlksRead' | 'totalTime';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSessionSqlV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/top-session-sql'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      size,
      sort,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopTrxSessionPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionDetailV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/top-trx-session'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getActiveBackendListPostgresqlV7ControllerAxios = ({
  instanceId,
  size,
  fromTime,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LongActiveBackendV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/trend/{instanceId}/long-activebackends'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      isFullText,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTrendTopObjectSortPostgresqlV7ControllerAxios = ({
  instanceId,
  size,
  fromTime,
  period,
  sort,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size: number;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  sort?:
    | 'age'
    | 'deadTupleRatio'
    | 'deadTuples'
    | 'deadTuplesSize'
    | 'deleteTuples'
    | 'insertTuples'
    | 'lastAnalyzeTime'
    | 'lastAutoAnalyzeTime'
    | 'lastAutoVacuumTime'
    | 'lastVacuumTime'
    | 'liveTuples'
    | 'scanCount'
    | 'tableScanRatio'
    | 'updateTuples';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TrendTopObjectListV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/trend/{instanceId}/top-object/sort'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      size,
      sort,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopUserPostgresqlV7ControllerAxios = ({
  size,
  sort,
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  size: number;
  sort:
    | 'calls'
    | 'maxTime'
    | 'meanTime'
    | 'sharedBlksHit'
    | 'sharedBlksRead'
    | 'tempRead'
    | 'totalTime';
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopUserV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/trend/top-user';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      size,
      sort,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getVacuumInfoPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<VacuumV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/vacuum'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const vacuumingBackendsPostgresqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  isFullText,
  isReport,
  period,
  size,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  isFullText?: boolean;
  isReport?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  timezone?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<VacuumingBackendV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/vacuuming-backends'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      isFullText,
      isReport,
      period,
      size,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterListPostgresqlV7ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LastParameterResponse> => {
  const localVarPath = '/api/v7/postgres/parameter/{instanceId}'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const killSessionsPostgresqlV7ControllerAxios = ({
  sessions,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sessions: Array<MultiSessionTargetForm>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MultiSessionKillResponse> => {
  const localVarPath = '/api/v7/postgres/activebackend/kill-sessions';
  const httpMethod = 'post';

  const data: any = sessions;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const killSessionPostgresqlV7ControllerAxios = ({
  instanceId,
  sessionId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  sessionId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/activebackend/kill-session'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      sessionId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const multiInstanceOverviewPostPostgresqlV7ControllerAxios = ({
  metricNames,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  metricNames: Array<string>;
  request: MultiInstanceRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MultiOverviewV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/overview';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      metricNames,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSqlPlanPostgresqlV7ControllerAxios = ({
  instanceId,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  request: SqlPlanRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlPlanV7Response> => {
  const localVarPath = '/api/v7/postgres/pa/{instanceId}/sqls/plan'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getAdditionalInfoPostgresqlV7ControllerAxios,
  getAdminReferencePostgresqlV7ControllerAxios,
  getCheckpointPostgresqlV7ControllerAxios,
  getFunctionDetailPostgresqlV7ControllerAxios,
  getFunctionSourcePostgresqlV7ControllerAxios,
  getObjectDetailPostgresqlV7ControllerAxios,
  getObjectSizeInfoPostgresqlV7ControllerAxios,
  getObjectNameListPostgresqlV7ControllerAxios,
  topObjectIndexScanPostgresqlV7ControllerAxios,
  getTopObjectPostgresqlV7ControllerAxios,
  getObjectSizeTrendPostgresqlV7ControllerAxios,
  getTopObjectAgePostgresqlV7ControllerAxios,
  getTopObjectAnalyzeTimePostgresqlV7ControllerAxios,
  getTopObjectDeadTuplePostgresqlV7ControllerAxios,
  getTopObjectScanCountPostgresqlV7ControllerAxios,
  getTopObjectTableScanRatioPostgresqlV7ControllerAxios,
  getTopObjectTableSpacePostgresqlV7ControllerAxios,
  multiInstanceOverviewPostgresqlV7ControllerAxios,
  getParameterHistoryPostgresqlV7ControllerAxios,
  getParameterHistoryDetailPostgresqlV7ControllerAxios,
  overviewPostgresqlV7ControllerAxios,
  getReplicationPostgresqlV7ControllerAxios,
  sessionsPostgresqlV7ControllerAxios,
  getSlowqueryPostgresqlV7ControllerAxios,
  getTopDiffStatisticsPostgresqlV7ControllerAxios,
  getTopFunctionPostgresqlV7ControllerAxios,
  getTopScanObjectV2PostgresqlV7ControllerAxios,
  getTopWaitEventPostgresqlV7ControllerAxios,
  getTopSessionSqlPostgresqlV7ControllerAxios,
  getTopTrxSessionPostgresqlV7ControllerAxios,
  getActiveBackendListPostgresqlV7ControllerAxios,
  getTrendTopObjectSortPostgresqlV7ControllerAxios,
  getTopUserPostgresqlV7ControllerAxios,
  getVacuumInfoPostgresqlV7ControllerAxios,
  vacuumingBackendsPostgresqlV7ControllerAxios,
  getParameterListPostgresqlV7ControllerAxios,
  killSessionsPostgresqlV7ControllerAxios,
  killSessionPostgresqlV7ControllerAxios,
  multiInstanceOverviewPostPostgresqlV7ControllerAxios,
  getSqlPlanPostgresqlV7ControllerAxios,
};
