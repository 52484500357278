/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  ApiResponseCloudEventLog,
  GlobalTempMetricV7Response,
  GlobalTempV7Response,
  LastParameterResponse,
  LongActiveThreadDetailV7Response,
  MultiInstanceRequest,
  MultiInstanceSlowV7Response,
  MultiOverviewV7Response,
  MultiSessionKillResponse,
  MultiSessionTargetForm,
  ObjectDetailTrendV7Response,
  ObjectInfoV7Response,
  ObjectTrendV7Response,
  OpenTableByIntervalV7Response,
  OverviewV7Response,
  ParameterDetailResponse,
  ParameterResponse,
  ParameterV7Response,
  RedoV7Response,
  ReplicationV7Response,
  SessionSearchListV7Response,
  SessionTempMetricV7Response,
  SessionTypeV7Response,
  SlowQueryDetailV7Response,
  SlowV7Response,
  SortByIntervalV7Response,
  SqlPlanRequest,
  SqlPlanV7Response,
  SqlStatErrCntGridV7Response,
  SqlTextBySqlIdV7Response,
  TablespaceV7Response,
  TopMetricV7Response,
  TopObjectV7Response,
  TopOldTransactionV7Response,
  TopSchemaV7Response,
  TopSortMergeV7Response,
  TopSqlFullScanV7Response,
  TopStatisticsV7Response,
  TopTableV7Response,
  TopTempSessionV7Response,
  TransactionDetailV7Response,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getCloudEventsMysqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseCloudEventLog> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/cloud-events'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const globalTempsMysqlV7ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<GlobalTempV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/global-temps'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const longActiveThreadDetailMysqlV7ControllerAxios = ({
  threadId,
  digest,
  fromTime,
  instanceId,
  period,
  queryStart,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  threadId: number;
  digest?: string;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  queryStart?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LongActiveThreadDetailV7Response> => {
  const localVarPath = '/api/v7/mysql/long-activethreads/{threadId}'.replace(
    `{${'threadId'}}`,
    encodeURIComponent(String(threadId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      digest,
      fromTime,
      instanceId,
      period,
      queryStart,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getObjectDetailMysqlV7ControllerAxios = ({
  instanceId,
  objectName,
  value,
  dbName,
  fromTime,
  interval,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  objectName: string;
  value:
    | 'count_delete'
    | 'count_fetch'
    | 'count_insert'
    | 'count_read'
    | 'count_update'
    | 'count_write'
    | 'data_size'
    | 'index_size'
    | 'row_scan_count'
    | 'row_scan_ratio'
    | 'sum_timer_delete'
    | 'sum_timer_fetch'
    | 'sum_timer_insert'
    | 'sum_timer_read'
    | 'sum_timer_update'
    | 'sum_timer_wait'
    | 'sum_timer_write'
    | 'total_size';
  dbName?: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ObjectDetailTrendV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/object/detail-trend'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dbName,
      fromTime,
      interval,
      objectName,
      period,
      timezone,
      toTime,
      value,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getObjectSizeInfoMysqlV7ControllerAxios = ({
  firstTime,
  instanceId,
  lastTime,
  objectName,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  firstTime: string;
  instanceId: string;
  lastTime: string;
  objectName?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ObjectInfoV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/object/info'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      firstTime,
      lastTime,
      objectName,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getObjectNameListMysqlV7ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ObjectTrendV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/object-names'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopObjectMysqlV7ControllerAxios = ({
  firstTime,
  instanceId,
  lastTime,
  col,
  dbName,
  objectName,
  size,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  firstTime: string;
  instanceId: string;
  lastTime: string;
  col?: 'diff_all_size' | 'first_all_size' | 'last_all_size';
  dbName?: string;
  objectName?: string;
  size?: number;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopObjectV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/object/top'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      col,
      dbName,
      firstTime,
      lastTime,
      objectName,
      size,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getObjectSizeTrendMysqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  interval,
  objectName,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  objectName?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ObjectTrendV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/object/trend'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      interval,
      objectName,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getOpenTableByIntervalMysqlV7ControllerAxios = ({
  interval,
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  interval:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<OpenTableByIntervalV7Response> => {
  const localVarPath = '/api/v7/mysql/open-table-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      interval,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getOverviewsMysqlV7ControllerAxios = ({
  instanceIds,
  metricNames,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  metricNames: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MultiOverviewV7Response> => {
  const localVarPath = '/api/v7/mysql/overview';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      metricNames,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getOverviewMysqlV7ControllerAxios = ({
  instanceId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<OverviewV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/overview'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterMysqlV7ControllerAxios = ({
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ParameterV7Response> => {
  const localVarPath = '/api/v7/mysql/parameter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterListMysqlV7ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LastParameterResponse> => {
  const localVarPath = '/api/v7/mysql/parameter/{instanceId}'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterHistoryMysqlV7ControllerAxios = ({
  baseTime,
  compareTime,
  instanceId,
  differentSelectors,
  name,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  baseTime: string;
  compareTime: string;
  instanceId: string;
  differentSelectors?: Array<boolean>;
  name?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ParameterResponse> => {
  const localVarPath = '/api/v7/mysql/parameter/history';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      baseTime,
      compareTime,
      differentSelectors,
      instanceId,
      name,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterHistoryDetailMysqlV7ControllerAxios = ({
  instanceId,
  name,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  name: string;
  timezone?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ParameterDetailResponse> => {
  const localVarPath = '/api/v7/mysql/parameter/history/detail';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      instanceId,
      name,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getRedoMysqlV7ControllerAxios = ({
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<RedoV7Response> => {
  const localVarPath = '/api/v7/mysql/redo';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getReplicationMysqlV7ControllerAxios = ({
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ReplicationV7Response> => {
  const localVarPath = '/api/v7/mysql/replication';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSessionCountByTypeMysqlV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionTypeV7Response> => {
  const localVarPath = '/api/v7/mysql/session-count';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionsMysqlV7ControllerAxios = ({
  instanceIds,
  db,
  digest,
  fromQueryStartTime,
  fromTime,
  isFullText,
  isReport,
  pageNum,
  pageSize,
  period,
  processId,
  programName,
  sqlText,
  state,
  tenantId,
  threadId,
  timezone,
  toQueryStartTime,
  toTime,
  user,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  db?: string;
  digest?: string;
  fromQueryStartTime?: string;
  fromTime?: string;
  isFullText?: boolean;
  isReport?: boolean;
  pageNum?: number;
  pageSize?: number;
  period?:
    | 'p10m'
    | 'p10s'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p1s'
    | 'p30m'
    | 'p30s'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p3s'
    | 'p5d'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  processId?: number;
  programName?: string;
  sqlText?: string;
  state?: string;
  tenantId?: string;
  threadId?: number;
  timezone?: string;
  toQueryStartTime?: string;
  toTime?: string;
  user?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionSearchListV7Response> => {
  const localVarPath = '/api/v7/mysql/sessions';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      db,
      digest,
      fromQueryStartTime,
      fromTime,
      instanceIds,
      isFullText,
      isReport,
      pageNum,
      pageSize,
      period,
      processId,
      programName,
      sqlText,
      state,
      tenantId,
      threadId,
      timezone,
      toQueryStartTime,
      toTime,
      user,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getMultiInstanceSlowMysqlV7ControllerAxios = ({
  instanceIds,
  fromTime,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MultiInstanceSlowV7Response> => {
  const localVarPath = '/api/v7/mysql/slow-queries';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSlowQueryMysqlV7ControllerAxios = ({
  fromTime,
  instanceId,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SlowV7Response> => {
  const localVarPath = '/api/v7/mysql/slow-query';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      isFullText,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSlowQueryDetailMysqlV7ControllerAxios = ({
  sqlId,
  threadId,
  time,
  fromTime,
  instanceId,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sqlId: string;
  threadId: number;
  time: string;
  fromTime?: string;
  instanceId?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SlowQueryDetailV7Response> => {
  const localVarPath = '/api/v7/mysql/slow-query-detail';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      isFullText,
      period,
      sqlId,
      threadId,
      time,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSortByIntervalMysqlV7ControllerAxios = ({
  interval,
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  interval:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SortByIntervalV7Response> => {
  const localVarPath = '/api/v7/mysql/sort-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      interval,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlTextMysqlV7ControllerAxios = ({
  instanceId,
  sqlId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  sqlId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlTextBySqlIdV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/sqls/sql-text'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      sqlId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTablespaceMysqlV7ControllerAxios = ({
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TablespaceV7Response> => {
  const localVarPath = '/api/v7/mysql/tablespace';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSqlStatErrCntGridMysqlV7ControllerAxios = ({
  fromTime,
  instanceId,
  isFullText,
  period,
  size,
  sort,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  sort?: 'errCount' | 'warnCount';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlStatErrCntGridV7Response> => {
  const localVarPath = '/api/v7/mysql/top-err-cnt-grid';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      isFullText,
      period,
      size,
      sort,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopGlobalTempMysqlV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<GlobalTempMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-global-temp';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topOldTransactionMysqlV7ControllerAxios = ({
  instanceId,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopOldTransactionV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/top-old-transaction'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopQueriesMysqlV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-queries';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopRedoUsageMysqlV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-redo-usage';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topSchemaMysqlV7ControllerAxios = ({
  fromTime,
  instanceId,
  period,
  size,
  sort,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  sort?:
    | 'avgLatency'
    | 'cpuTime'
    | 'execCount'
    | 'maxLatency'
    | 'rowsExamined'
    | 'rowsSent'
    | 'sortMergePasses'
    | 'tmpDiskTables'
    | 'totalLatency';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSchemaV7Response> => {
  const localVarPath = '/api/v7/mysql/top-schema';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      size,
      sort,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topTempSessionUsageMysqlV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionTempMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-session-temp';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topTempSessionListMysqlV7ControllerAxios = ({
  instanceId,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopTempSessionV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/top-session-temp'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopSortListMysqlV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-sort-list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topSortMergeMysqlV7ControllerAxios = ({
  instanceId,
  size,
  sort,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  size?: number;
  sort?: 'merge' | 'mergeAvg' | 'rowsAvg';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSortMergeV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/top-sort-merge'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      size,
      sort,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topSqlFullScanMysqlV7ControllerAxios = ({
  fromTime,
  instanceId,
  isFullText,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSqlFullScanV7Response> => {
  const localVarPath = '/api/v7/mysql/top-sql-full-scan';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      isFullText,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopStatisticsMysqlV7ControllerAxios = ({
  fromTime,
  instanceId,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopStatisticsV7Response> => {
  const localVarPath = '/api/v7/mysql/top-statistics';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topTableMysqlV7ControllerAxios = ({
  instanceId,
  dbName,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  dbName?: string;
  size?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopTableV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/top-table'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dbName,
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const transactionDetailMysqlV7ControllerAxios = ({
  instanceId,
  pid,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  pid: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TransactionDetailV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/transactions/{pid}'
    .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)))
    .replace(`{${'pid'}}`, encodeURIComponent(String(pid)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getOverviewsPostMysqlV7ControllerAxios = ({
  metricNames,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  metricNames: Array<string>;
  request: MultiInstanceRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MultiOverviewV7Response> => {
  const localVarPath = '/api/v7/mysql/overview';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      metricNames,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSessionCountByTypePostMysqlV7ControllerAxios = ({
  queryParam,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  queryParam: MultiInstanceRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionTypeV7Response> => {
  const localVarPath = '/api/v7/mysql/session-count';
  const httpMethod = 'post';

  const data: any = queryParam;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getMultiInstanceSlowPostMysqlV7ControllerAxios = ({
  queryParam,
  isFullText,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  queryParam: MultiInstanceRequest;
  isFullText?: boolean;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MultiInstanceSlowV7Response> => {
  const localVarPath = '/api/v7/mysql/slow-queries';
  const httpMethod = 'post';

  const data: any = queryParam;
  const config: any = {
    params: {
      isFullText,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlPlanMysqlV7ControllerAxios = ({
  instanceId,
  sqlPlanRequest,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  sqlPlanRequest: SqlPlanRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlPlanV7Response> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/sqls/plan'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'post';

  const data: any = sqlPlanRequest;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const killSessionMysqlV7ControllerAxios = ({
  instanceId,
  pid,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  pid: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v7/mysql/{instanceId}/threads/kill-session'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      pid,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const killSessionsMysqlV7ControllerAxios = ({
  sessions,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sessions: Array<MultiSessionTargetForm>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MultiSessionKillResponse> => {
  const localVarPath = '/api/v7/mysql/threads/kill-sessions';
  const httpMethod = 'post';

  const data: any = sessions;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopGlobalTempPostMysqlV7ControllerAxios = ({
  queryParam,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  queryParam: MultiInstanceRequest;
  size?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<GlobalTempMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-global-temp';
  const httpMethod = 'post';

  const data: any = queryParam;
  const config: any = {
    params: {
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopQueriesPostMysqlV7ControllerAxios = ({
  queryParam,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  queryParam: MultiInstanceRequest;
  size?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-queries';
  const httpMethod = 'post';

  const data: any = queryParam;
  const config: any = {
    params: {
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopRedoUsagePostMysqlV7ControllerAxios = ({
  queryParam,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  queryParam: MultiInstanceRequest;
  size?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-redo-usage';
  const httpMethod = 'post';

  const data: any = queryParam;
  const config: any = {
    params: {
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topTempSessionUsagePostMysqlV7ControllerAxios = ({
  request,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: MultiInstanceRequest;
  size?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionTempMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-session-temp';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopSortListPostMysqlV7ControllerAxios = ({
  queryParam,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  queryParam: MultiInstanceRequest;
  size?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopMetricV7Response> => {
  const localVarPath = '/api/v7/mysql/top-sort-list';
  const httpMethod = 'post';

  const data: any = queryParam;
  const config: any = {
    params: {
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getCloudEventsMysqlV7ControllerAxios,
  globalTempsMysqlV7ControllerAxios,
  longActiveThreadDetailMysqlV7ControllerAxios,
  getObjectDetailMysqlV7ControllerAxios,
  getObjectSizeInfoMysqlV7ControllerAxios,
  getObjectNameListMysqlV7ControllerAxios,
  getTopObjectMysqlV7ControllerAxios,
  getObjectSizeTrendMysqlV7ControllerAxios,
  getOpenTableByIntervalMysqlV7ControllerAxios,
  getOverviewsMysqlV7ControllerAxios,
  getOverviewMysqlV7ControllerAxios,
  getParameterMysqlV7ControllerAxios,
  getParameterListMysqlV7ControllerAxios,
  getParameterHistoryMysqlV7ControllerAxios,
  getParameterHistoryDetailMysqlV7ControllerAxios,
  getRedoMysqlV7ControllerAxios,
  getReplicationMysqlV7ControllerAxios,
  getSessionCountByTypeMysqlV7ControllerAxios,
  sessionsMysqlV7ControllerAxios,
  getMultiInstanceSlowMysqlV7ControllerAxios,
  getSlowQueryMysqlV7ControllerAxios,
  getSlowQueryDetailMysqlV7ControllerAxios,
  getSortByIntervalMysqlV7ControllerAxios,
  sqlTextMysqlV7ControllerAxios,
  getTablespaceMysqlV7ControllerAxios,
  getSqlStatErrCntGridMysqlV7ControllerAxios,
  getTopGlobalTempMysqlV7ControllerAxios,
  topOldTransactionMysqlV7ControllerAxios,
  getTopQueriesMysqlV7ControllerAxios,
  getTopRedoUsageMysqlV7ControllerAxios,
  topSchemaMysqlV7ControllerAxios,
  topTempSessionUsageMysqlV7ControllerAxios,
  topTempSessionListMysqlV7ControllerAxios,
  getTopSortListMysqlV7ControllerAxios,
  topSortMergeMysqlV7ControllerAxios,
  topSqlFullScanMysqlV7ControllerAxios,
  getTopStatisticsMysqlV7ControllerAxios,
  topTableMysqlV7ControllerAxios,
  transactionDetailMysqlV7ControllerAxios,
  getOverviewsPostMysqlV7ControllerAxios,
  getSessionCountByTypePostMysqlV7ControllerAxios,
  getMultiInstanceSlowPostMysqlV7ControllerAxios,
  sqlPlanMysqlV7ControllerAxios,
  killSessionMysqlV7ControllerAxios,
  killSessionsMysqlV7ControllerAxios,
  getTopGlobalTempPostMysqlV7ControllerAxios,
  getTopQueriesPostMysqlV7ControllerAxios,
  getTopRedoUsagePostMysqlV7ControllerAxios,
  topTempSessionUsagePostMysqlV7ControllerAxios,
  getTopSortListPostMysqlV7ControllerAxios,
};
